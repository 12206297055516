<template>
  <div class="gestion-appel-paiement-obligee">
    <div
      class="loader-obligee-delete-projet"
      v-if="getLoaderDelteProjectVertigo"
    >
      <Half-circle-spinner
        :animation-duration="1000"
        :size="50"
        :color="'#4d4bac'"
        class="loeder"
      />
    </div>
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2" v-if="checkPermission('GAPTHFDD')">
          <div class="label-box-style w-93-px">
            <span class="title-tabel-obligie">Date début</span>
          </div>
          <!-- :clearable="false"
            :editable="false" -->
          <date-picker
            value-type="format"
            type="date"
            required
            :value="date_debut"
            @input="handleDateTimePicker($event, 'date_debut')"
            :disabled-date="disabledStartDate"
            input-class="custom-date-picker-filter"
            class="custom-date-picker-calender-filter"
          ></date-picker>
        </div>
        <div class="box-label-champ mr-2" v-if="checkPermission('GAPTHFDF')">
          <div class="label-box-style w-63-px">
            <span class="title-tabel-obligie">Date fin</span>
          </div>
          <date-picker
            value-type="format"
            type="date"
            :value="date_fin"
            @input="handleDateTimePicker($event, 'date_fin')"
            :clear-button="true"
            :disabled-date="disabledEndDate"
            input-class="custom-date-picker-filter"
            class="custom-date-picker-calender-filter"
          ></date-picker>
        </div>

        <div v-if="getChargementAppelPaiementThObligee" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="error-message ml-2">
          <div v-if="getErrorAppeliPaiementThObligee" class="error">
            <ul
              v-if="Array.isArray(getErrorAppeliPaiementThObligee)"
              class="mb-0"
            >
              <li
                v-for="(e, index) in getErrorAppeliPaiementThObligee"
                :key="index"
              >
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorAppeliPaiementThObligee }}</div>
          </div>
        </div>

        <div class="error-message ml-2">
          <div
            v-if="getErrorAppeliPaiementUpdateThObligee"
            class="error_oblige_update"
          >
            <b-alert show dismissible fade variant="danger">
              {{ getErrorAppeliPaiementUpdateThObligee }}
            </b-alert>
          </div>
        </div>

        <div class="box-end-position">
          <search
            v-if="checkPermission('GAPTHRE')"
            :value="searchValue"
            @changeSearchValue="changeSearchValue"
            :dynamic="false"
            placeHolder="Num dossier"
          />
          <GenerateFactureMaster
            v-if="checkPermission('GAPTHGFPPM')"
            :functionGenerate="generateFactureMastersObliges"
          />
          <export-rapport-oblige
            v-if="checkPermission('GAPTHABGR')"
            :genereRapport="exportRapportFactureObligee"
            :filtre="ComputedFilter"
          />
          <b-button
            size="sm"
            class="button-export-style ml-2 pl-2 pr-2"
            title="Filter"
            id="popover-filter-obligee"
          >
            <font-awesome-icon icon="filter" />
          </b-button>
          <b-popover
            triggers="focus"
            target="popover-filter-obligee"
            placement="top"
            custom-class="my-custom-class-popover-filter"
          >
            <div
              class="box-label-champ mr-2 mb-2 mt-2"
              v-if="checkPermission('GAPTHFO')"
            >
              <div class="label-box-style w-126-px">
                <span class="title-tabel-obligie">Organsime</span>
              </div>
              <multiselect
                v-model="filterOrganisme"
                :options="getListOrganismeObligee"
                :multiple="true"
                label="name"
                track-by="id"
                class="customSelectMultipleFilter"
                :showLabels="false"
                placeholder="sélectionner organisme"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :loading="getLoadingDataFilterObligeeThProjects"
                @input="handleFilter"
              >
                <template slot="selection" slot-scope="{ values, isOpen }"
                  ><span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.length"
                    >{{ values.length }} options selected</span
                  ></template
                >
              </multiselect>
            </div>

            <div
              class="box-label-champ mr-2 mb-2 mt-2"
              v-if="checkPermission('GAPTHFFI')"
            >
              <div class="label-box-style w-126-px">
                <span class="title-tabel-obligie">Filiale Installateur</span>
              </div>
              <multiselect
                v-model="filterFiliale"
                :options="getListInstallateurbligee"
                :multiple="true"
                label="name"
                track-by="id"
                class="customSelectMultipleFilter"
                :showLabels="false"
                placeholder="sélectionner filiale"
                :loading="getLoadingDataFilterObligeeThProjects"
                @input="handleFilter"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
              >
                <template slot="selection" slot-scope="{ values, isOpen }"
                  ><span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.length"
                    >{{ values.length }} options selected</span
                  ></template
                >
              </multiselect>
            </div>
            <div
              class="box-label-champ mr-2 mt-2"
              v-if="checkPermission('GAPTHFL')"
            >
              <div class="label-box-style w-126-px">
                <span class="title-tabel-obligie">Lot</span>
              </div>
              <multiselect
                v-model="filterLot"
                :options="getListLotsOligee"
                :multiple="true"
                class="customSelectMultipleFilter"
                :showLabels="false"
                placeholder="sélectionner lot"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :loading="getLoadingDataFilterObligeeThProjects"
                @input="handleFilter"
              >
                <template slot="selection" slot-scope="{ values, isOpen }"
                  ><span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.length"
                    >{{ values.length }} options selected</span
                  ></template
                >
              </multiselect>
            </div>
          </b-popover>
          <vue-oblige-facture />
          <deleteProject
            class="ml-1"
            :dataToUse="computedCheckedRowsWithFact"
            v-if="
              checkPermission('GAPTHDP') && computedCheckedRowsWithFact.length
            "
          />
          <b-button
            size="sm"
            variant="danger"
            class="button-export-style icon-danger-c"
            v-if="
              missingOrganismeInObligeeFile.length &&
                checkPermission('GAPTHDSO')
            "
            v-b-modal.missingOrganisme
          >
            <!-- <span style="">{{ missingOrganismeInObligeeFile.length }}</span> -->
            <font-awesome-icon icon="house-user" />
          </b-button>
          <b-button
            size="sm"
            class="button-export-style ml-2"
            @click="refreshAooelPaiementObligee"
            v-if="checkPermission('GAPTHRL') && computedCheckedRows.length"
          >
            <img src="@/assets/arrows_rotate.png"
          /></b-button>
          <b-button
            size="sm"
            class="button-export-style ml-2 pl-2 pr-2"
            title="insertion xls"
            @click="handleUploadToExcel"
            v-if="checkPermission('GAPTHIX')"
          >
            <font-awesome-icon icon="file-import"
          /></b-button>
          <b-button
            size="sm"
            class="button-export-style pl-2 pr-2 ml-2"
            @click.prevent="openConfirmationGlobalModal()"
            title="Validation globale des factures"
            v-if="checkPermission('GAPTHVGF')"
            ><font-awesome-icon icon="check-double" class="file-medical"
          /></b-button>
          <export-excel-facture
            @exportFactureXls="exportExcelFacture"
            :resetModalExportFacture="computedResetModalExportFacture"
            v-if="checkPermission('GAPTHXLS')"
          />
          <export-excel-facture-pdf
            @exportFacturePdf="exportFacturePdf"
            v-if="checkPermission('GAPTHTFP') && computedgetLotsToSend.length"
          />
          <div
            v-if="getMailLoadingVertigo"
            class="loading loader-mail-size mt-1 mr-2 ml-2"
          >
            <div class="spinner-border loadingFetch" role="status"></div>
          </div>
          <MailComponent
            v-if="
              computedgetLotsToSend.length &&
                !getMailLoadingVertigo &&
                checkPermission('GAPTHEE')
            "
            :lotsToSend="computedgetLotsToSend"
            :clientToSend="computedAcheuteurToSend"
          />
          <!--  -->
          <!-- <b-button
            size="sm"
            class="button-export-style  ml-2"
            @click="exportFiles"
            >Zip</b-button
          > -->
        </div>
      </div>
    </div>
    <!-- block filtre -->
    <div>
      <div
        v-if="
          date_debut !== null ||
            date_fin !== null ||
            (searchValue !== null && searchValue !== '')
        "
        class="block-filter-style"
      >
        <span class="phrase-style">Les filtres choisie sont: </span>
        <span class="item-filter-style" v-if="date_debut !== null"
          >Date début: {{ date_debut }}</span
        >
        <span class="item-filter-style" v-if="date_fin !== null"
          >Date fin: {{ date_fin }}</span
        >
        <span
          v-if="searchValue !== null && searchValue !== ''"
          class="item-filter-style"
          >Recherche:{{ searchValue }}</span
        >
      </div>
    </div>
    <!--End block filtre -->
    <div class="chip-filters-simulation">
      <div v-if="filterOrganisme.length" class="block-filter">
        <span class="title-block-chip">Organisme : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="filterOrganisme"
          @input="handleFilter"
        >
          <vs-chip
            :key="organisme.name + 'master-filiale'"
            @click="remove(organisme, 'filterOrganisme', 'handleFilter')"
            v-for="organisme in filterOrganisme"
            closable
          >
            {{ organisme.name }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="filterFiliale.length" class="block-filter">
        <span class="title-block-chip">Filiale Installateur </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="filterFiliale"
          @input="handleFilter"
        >
          <vs-chip
            :key="filiale.name + 'filiale'"
            @click="remove(filiale, 'filterFiliale', 'handleFilter')"
            v-for="filiale in filterFiliale"
            closable
          >
            {{ filiale.name }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="filterLot.length" class="block-filter">
        <span class="title-block-chip">Lot : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="filterLot"
          @input="handleFilter"
        >
          <vs-chip
            :key="lot.name + 'depot'"
            @click="remove(lot, 'filterLot', 'handleFilter')"
            v-for="lot in filterLot"
            closable
          >
            {{ lot }}
          </vs-chip>
        </vs-chips>
      </div>
    </div>
    <div class="body-box-rapport" :style="sizeBlockTable">
      <div class="table-rapport-style w-100-p">
        <div>
          <b-table
            ref="table"
            show-empty
            id="my-table"
            class="
              custom-table-style
              table-appel-paiement-obligee-th-with-flesh table-header-regie
            "
            :class="
              computedTotalProjectSelected > 0
                ? 'custom-table-style table-appel-paiement-obligee-th-with-summ table-header-regie'
                : 'custom-table-style table-appel-paiement-obligee-th table-header-regie'
            "
            :items="getAppeliPaiementThTableObligee"
            :fields="computedFields2"
            :style="sizeTable"
            bordered
            sticky-header
            hover
            responsive
            head-variant="light"
            :tbody-tr-class="rowClass"
            empty-text="Il n'y a aucun enregistrement à afficher"
          >
            <template v-slot:head()="data">
              <div>
                <font-awesome-icon
                  class="icon-plus align-icon"
                  icon="plus-square"
                  v-if="
                    computedHeaderVisibility(data.field.key) &&
                      $data['details_' + data.field.key] == false
                  "
                  @click="setVisibilityFieldsHeader(data.field)"
                />
                <font-awesome-icon
                  class="icon-plus align-icon"
                  icon="minus-square"
                  v-if="
                    computedHeaderVisibility(data.field.key) &&
                      $data['details_' + data.field.key] == true
                  "
                  @click="setVisibilityFieldsHeader(data.field)"
                />
              </div>
              <div v-if="data.field.key == 'check_all'">
                <b-form-checkbox
                  v-model="checkAll"
                  @change="checkAllFunction"
                  class="check-th"
                  :value="true"
                  :unchecked-value="false"
                >
                </b-form-checkbox>
              </div>
              <span v-if="data.field.coef || data.field.statue">
                {{ data.field.label }}
                <span v-if="data.field.coef">
                  <column-header-with-tool-tip
                    v-if="
                      computedCheckedRows.length &&
                        checkPermission('GAPTHMCPIPS')
                    "
                    :rowDetails="false"
                    :label="data.field.label"
                    :column="data.field.key"
                    :dataToUse="getAppeliPaiementThTableObligee"
                    :updateFunction="updateCoefObligee"
                    @reloadData="handleFilter"
                /></span>

                <span v-if="data.field.statue">
                  <validation-statue-obligee
                    v-if="data.field.editable && computedCheckedRowsLots.length"
                    :dataToUse="getAppeliPaiementThTableObligee"
                    @reloadData="handleFilter"
                    :type="data.field.key"/></span
              ></span>

              <span v-if="!data.field.statue && !data.field.coef">
                {{ data.field.label }}</span
              >
            </template>
            <template v-slot:cell(check_all)="data">
              <b-form-checkbox
                v-model="data.item.check"
                @change="checkAllVerif(data.item)"
                class="check-th"
                :value="true"
                :unchecked-value="false"
              >
              </b-form-checkbox>
            </template>
            <template v-slot:cell(name)="data">
              <div class="d-flex justify-content-between ml-1 mr-1">
                <span class="ml-1"> {{ data.item.name }}</span>
                <font-awesome-icon
                  icon="check-circle"
                  class="icon-style-check-facture"
                  v-if="computedCHeckFiliale(data.item)"
                />
              </div>
            </template>
            <template v-slot:cell(commission_pu)=""> - </template>
            <template v-slot:cell(commission)=""> - </template>
            <template v-slot:cell(kwh_cumac)=""> - </template>
            <template v-slot:cell(m_cumac)=""> - </template>
            <template v-slot:cell(commission_ttc)=""> - </template>
            <template v-slot:cell(commission_tva)=""> - </template>
            <!-- <template v-slot:cell(commission_statut)=""> - </template> -->
            <template v-slot:cell(prime_pu)=""> - </template>
            <template v-slot:cell(prime)=""> - </template>
            <template v-slot:cell(prime_ttc)=""> - </template>
            <template v-slot:cell(prime_tva)=""> - </template>
            <!-- <template v-slot:cell(prime_statut)=""> - </template> -->
            <template v-slot:cell(surprime_pu)=""> - </template>
            <template v-slot:cell(surprime)=""> - </template>
            <template v-slot:cell(surprime_ttc)=""> - </template>
            <template v-slot:cell(surprime_tva)=""> - </template>
            <!-- <template v-slot:cell(surprime_statut)=""> - </template> -->
            <template v-slot:cell(missing_prime)=""> - </template>
            <template v-slot:cell(missing_prime_ttc)=""> - </template>
            <template v-slot:cell(missing_prime_tva)=""> - </template>
            <!-- <template v-slot:cell(missing_prime_statut)=""> - </template> -->
            <template v-slot:cell(total)=""> - </template>
            <!-- <template v-slot:cell(avoir)=""> - </template> -->
            <!-- <template v-slot:cell(missing_prime_av_statut)=""> - </template> -->
            <template v-slot:cell(nom)=""> - </template>
            <template v-slot:cell(organisme)=""> - </template>
            <template v-slot:cell(reste_a_charge)=""> - </template>
            <template v-slot:cell(montant_ttc_avoir)=""> - </template>
            <template v-slot:cell(montant_total_ttc)=""> - </template>
            <template v-slot:cell(montant_prime_cee)=""> - </template>
            <template v-slot:cell(coef_prime_installateur)=""> - </template>
            <template v-slot:cell(missing_prime_pu)=""> - </template>
            <template v-slot:cell(missing_num_facture)=""> - </template>
            <!-- <template v-slot:cell(missing_prime_av_num_facture)="">
              -
            </template> -->
            <template v-slot:cell(prime_num_facture)=""> - </template>
            <template v-slot:cell(surprime_num_facture)=""> - </template>
            <template v-slot:cell(commission_num_facture)=""> - </template>
            <template v-slot:cell(commission_avoir)=""> - </template>
            <template v-slot:cell(prime_installateur_avoir)=""> - </template>
            <template v-slot:cell(comm_installateur_avoir)=""> - </template>
            <template v-slot:cell(surprime_avoir)=""> - </template>
            <template v-slot:cell(commission_gestion)=""> - </template>
            <template v-slot:cell(commission_gestion_pu)=""> - </template>
            <template v-slot:cell(commission_gestion_ttc)=""> - </template>
            <template v-slot:cell(commission_gestion_tva)=""> - </template>
            <!-- <template v-slot:cell(commission_gestion_statut)=""> - </template> -->
            <template v-slot:cell(commission_gestion_num_facture)="">
              -
            </template>
            <template v-slot:cell(commission_gestion_avoir)=""> - </template>
            <!--  -->
            <template v-slot:cell(consolidee_energie_invest_pu)="">
              -
            </template>
            <template v-slot:cell(consolidee_energie_invest)="">
              -
            </template>
            <template v-slot:cell(consolidee_energie_invest_ttc)="">
              -
            </template>
            <template v-slot:cell(consolidee_energie_invest_tva)="">
              -
            </template>
            <template v-slot:cell(consolidee_energie_invest_num_facture)="">
              -
            </template>
            <template v-slot:cell(consolidee_energie_invest_avoir)="">
              -
            </template>
            <template v-slot:cell(details)="data">
              <button class="btn btn-anomalie" @click="data.toggleDetails">
                <font-awesome-icon
                  class="icon-plus"
                  icon="plus-square"
                  v-if="data.detailsShowing == false"
                />
                <font-awesome-icon
                  class="icon-plus"
                  icon="minus-square"
                  v-if="data.detailsShowing == true"
                />
              </button>
            </template>
            <template #row-details="data">
              <span
                v-for="(item, index) in data.item.lots"
                :key="data.item.name + index"
              >
                <template
                  ><span
                    class="align-td-item border-botom background-week"
                    :class="{ ligneAnomalieObligee: item.error == true }"
                  >
                    <td class="item-details-style col-check-obligee">
                      <b-form-checkbox
                        v-model="item.check"
                        @change="checkAllVerifDetails(data.item, item)"
                        class="check-th-details ml-2"
                        :value="true"
                        :unchecked-value="false"
                      >
                      </b-form-checkbox>
                    </td>
                    <td class="item-details-style col-plus-obligee">
                      <button
                        class="btn btn-anomalie"
                        @click="item.showDetails = !item.showDetails"
                        :disabled="item.projects.length > 0 ? false : true"
                      >
                        <font-awesome-icon
                          class="icon-plus-double"
                          icon="angle-right"
                          v-if="item.showDetails == false"
                        />
                        <font-awesome-icon
                          class="icon-plus-double"
                          icon="angle-down"
                          v-if="item.showDetails == true"
                        />
                      </button>
                    </td>
                    <td class="item-details-style num-lot-td">
                      <span class="d-flex"
                        ><font-awesome-icon
                          :id="'tooltip-target-lot' + item.name"
                          icon="info"
                          class="info-icon-commercial-lot w-25"
                          @click.prevent.stop="
                            $refs['tooltip-target-lot' + item.name].show()
                          "
                        />
                        <!-- <mail-component :lot="item.name" /> -->
                        {{ item.name }}
                      </span>

                      <b-tooltip
                        :target="'tooltip-target-lot' + item.name"
                        triggers="hover"
                      >
                        <div>
                          <h5>Vendeur :</h5>
                          {{ item.name_vendeur }}
                        </div>
                        <div>
                          <h5>Groupe :</h5>
                          {{ item.name_group }}
                        </div>
                        <div>
                          <h5>Client :</h5>
                          {{ item.name_acheteur }}
                        </div>
                      </b-tooltip>
                    </td>
                    <td class="item-details-style">-</td>
                    <td class="item-details-style">-</td>
                    <td class="item-details-style">
                      {{ item.reste_a_charge ? item.reste_a_charge : '-' }}
                    </td>
                    <td class="item-details-style">
                      {{ item.ttc_avoir ? item.ttc_avoir : '-' }}
                    </td>
                    <td class="item-details-style">
                      {{
                        item.montant_total_ttc ? item.montant_total_ttc : '-'
                      }}
                    </td>
                    <td class="item-details-style">
                      {{ item.cee ? item.cee : '-' }}
                    </td>
                    <td class="item-details-style">
                      {{ item.kwh ? item.kwh : '-' }}
                    </td>
                    <td class="item-details-style">
                      {{ item.mega ? item.mega : '-' }}
                    </td>
                    <td class="item-details-style">-</td>
                    <!-- prime -->
                    <td
                      class="
                        item-details-style
                        justify-content-sb
                        th-montant-obligee
                      "
                      v-if="checkPermission('GAPTHACP')"
                    >
                      <div class="center-th-montant">
                        <div
                          v-show="
                            item &&
                              item.prime_factures &&
                              item.prime_factures.length
                          "
                        >
                          <font-awesome-icon
                            :id="`popover-1-prime-${data.item.name}-${index}`"
                            icon="info"
                            class="
                              info-icon-commercial-lot
                              content-tooltip-style
                              w-25
                            "
                          />
                        </div>
                        <div class="margin-text-tooltip">
                          <p class="text-avoir-color ml-1">
                            {{ item.prime_ht }} €
                          </p>
                        </div>

                        <b-popover
                          :target="`popover-1-prime-${data.item.name}-${index}`"
                          :triggers="computedPopover"
                          :custom-class="computedStyle"
                        >
                          <div
                            v-for="(prime, i) in item.prime_factures"
                            :key="i"
                          >
                            <div class="d-flex justify-content-space-between">
                              <span class="mr-2"
                                >{{ prime.num }}, {{ prime.total_ht }} €</span
                              >
                              <template-obligee
                                type="prime"
                                type2="non-avoir"
                                :item="item"
                                :show_generate_facture_file_code="
                                  !item.error &&
                                    item.prime_statut != 'Payé' &&
                                    checkPermission('GAPTHVFP') &&
                                    !item.email_sent_at &&
                                    prime.FactureAvoir == 0
                                "
                                :updateFunction="
                                  uploadObligeeTemplateInServieVisionFiliale
                                "
                                :getTemplateFunction="
                                  getObligeeTemplateAndFiledsDataToUpdateForFcature
                                "
                                :refereshTemplateFunction="
                                  refreshFactureDossier
                                "
                                :downloadFactureApi="downloadFactureObligeeItem"
                                :download="prime.id"
                                :showDownloadButton="
                                  checkPermission('GAPTHTFPR')
                                "
                                :value="prime.total_ht"
                                :can_reload="item.can_reload"
                                :visialisation="checkPermission('GAPTHVFO')"
                                @returnHistorique="returnHistorique"
                                :body="prime.body"
                              />
                              <template-obligee
                                type="prime"
                                type2="avoir"
                                :item="item"
                                :updateFunction="uploadObligeAvoir"
                                :show_generate_facture_file_code="
                                  !item.error &&
                                    item.prime_statut != 'Payé' &&
                                    checkPermission('GAPTHFAP') &&
                                    prime.reste_avoir != 0
                                "
                                :getTemplateFunction="
                                  getObligeeTemplateAndFiledsDataToUpdateForFcature
                                "
                                :refereshTemplateFunction="
                                  refreshFactureDossier
                                "
                                :download="prime.id"
                                :showDownloadButton="false"
                                :value="prime.total_ht"
                                :can_reload="item.can_reload"
                                IconClass="red-color"
                                @returnHistorique="returnHistorique"
                                :filter="ComputedFilter"
                              />
                              <Historique
                                :awesome="true"
                                :permission="checkPermission('GAPTHAHPR')"
                                :dataToUse="prime.logs"
                                :index="prime.id"
                                @returnHistorique="returnHistorique"
                              />
                              <!-- </div> -->
                            </div>
                          </div>
                        </b-popover>

                        <template-obligee
                          v-if="
                            !item.error_vendeur &&
                              !item.error_acheteur &&
                              !item.error_group
                          "
                          type="prime"
                          type2="non-avoir"
                          :show_generate_facture_file_code="
                            !item.error &&
                              item.prime_statut != 'Payé' &&
                              checkPermission('GAPTHVFP')
                          "
                          :item="item"
                          :updateFunction="
                            uploadObligeeTemplateInServieVisionFiliale
                          "
                          :getTemplateFunction="
                            getObligeeTemplateAndFiledsDataToUpdate
                          "
                          :refereshTemplateFunction="refreshFactureDossier"
                          :download="item.prime_factures.map(i => i.id)"
                          :showDownloadButton="
                            checkPermission('GAPTHTFPR') &&
                              item.prime_factures.length
                          "
                          :value="item.prime_ht"
                          :can_reload="item.can_reload"
                          :downloadFactureApi="downloadMultiAvoirPdfs"
                        />
                      </div>
                    </td>
                    <template v-if="computedShowSubRow('prime')">
                      <td class="item-details-style">-</td>
                      <td class="item-details-style">{{ item.prime_ttc }} €</td>
                      <td class="item-details-style">{{ item.prime_tva }} €</td>
                      <td class="item-details-style">
                        {{ item.prime_num_facture }}
                      </td></template
                    >
                    <td
                      class="item-details-style col-montant-obligee"
                      v-if="checkPermission('GAPTHACAP')"
                    >
                      <div class="center-th-montant">
                        <div>
                          <div class="tooltip-position">
                            <div
                              v-show="
                                item &&
                                  item.prime_factures &&
                                  item.prime_factures.length
                              "
                            >
                              <font-awesome-icon
                                :id="`popover-1-${data.item.name}-${index}`"
                                icon="info"
                                class="
                                  info-icon-commercial-lot
                                  content-tooltip-style
                                  w-25
                                "
                              />
                            </div>
                            <div class="margin-text-tooltip">
                              <p class="text-avoir-color ml-1">
                                {{ item.prime_avoir }} €
                              </p>
                            </div>
                          </div>
                          <b-popover
                            :target="`popover-1-${data.item.name}-${index}`"
                            :triggers="computedPopover"
                            :custom-class="computedStyle"
                          >
                            <div>
                              <div class="d-flex align-items-center">
                                <div class="d-inline-block">
                                  <b-table-simple class="table-fiche">
                                    <b-tbody>
                                      <b-tr class="title-ligne">
                                        <b-th class="newcolor">Facture</b-th>
                                        <b-th class="newcolor">Avoir</b-th>
                                        <b-th class="newcolor">HT</b-th>
                                        <b-th class="newcolor"></b-th>
                                      </b-tr>
                                      <template
                                        v-for="(fact,
                                        iF) in item.prime_factures.filter(
                                          i => i.FactureAvoir.length
                                        )"
                                        ><template
                                          v-for="(factAv,
                                          i) in fact.FactureAvoir"
                                        >
                                          <b-tr
                                            :key="'factAv' + i + iF"
                                            :class="
                                              iF % 2 != 0
                                                ? 'title-ligne'
                                                : 'title-ligne-child'
                                            "
                                          >
                                            <b-td class="newcolor"
                                              >{{ fact.num }}
                                            </b-td>
                                            <b-td class="newcolor"
                                              >{{ factAv.num }}
                                            </b-td>
                                            <b-td class="newcolor"
                                              >{{ factAv.total_ht }} €</b-td
                                            >
                                            <b-td class="newcolor">
                                              <font-awesome-icon
                                                class="mr-2"
                                                v-if="
                                                  checkPermission('GAPTHVFAP')
                                                "
                                                icon="eye"
                                                @click="
                                                  handleOpenAvoirVusialisationModal(
                                                    factAv
                                                  )
                                                "/>
                                              <font-awesome-icon
                                                v-if="
                                                  checkPermission('GAPTHTPFAP')
                                                "
                                                icon="arrow-alt-circle-down"
                                                @click="clickButton(factAv.id)"
                                            /></b-td>
                                          </b-tr> </template
                                      ></template> </b-tbody
                                  ></b-table-simple>
                                </div>
                              </div>
                            </div>
                          </b-popover>
                        </div>
                      </div>
                    </td>
                    <!-- Missing prime -->
                    <td
                      class="
                        item-details-style
                        justify-content-sb
                        th-montant-obligee
                      "
                      v-if="checkPermission('GAPTHACI')"
                    >
                      <div class="center-th-montant">
                        <div class="tooltip-position">
                          <div
                            v-show="
                              item &&
                                item.missing_prime_factures &&
                                item.missing_prime_factures.length
                            "
                          >
                            <font-awesome-icon
                              :id="
                                `popover-1-missing_prime-${data.item.name}-${index}`
                              "
                              icon="info"
                              class="
                                info-icon-commercial-lot
                                content-tooltip-style
                                w-25
                              "
                            />
                          </div>

                          <div class="margin-text-tooltip">
                            <p
                              class="text-avoir-color ml-1"
                              :class="{ 'red-color': item.warning }"
                            >
                              {{ item.missing_prime_ht }} €
                            </p>
                          </div>

                          <b-popover
                            :target="
                              `popover-1-missing_prime-${data.item.name}-${index}`
                            "
                            :triggers="computedPopover"
                            :custom-class="computedStyle"
                          >
                            <div
                              v-for="(mp, i) in item.missing_prime_factures"
                              :key="i"
                            >
                              <div class="d-flex justify-content-space-between">
                                <span class="mr-2">
                                  {{ mp.num }}, {{ mp.total_ht }} €</span
                                >
                                <template-obligee
                                  type="missing_prime"
                                  type2="non-avoir"
                                  :item="item"
                                  :show_generate_facture_file_code="
                                    !item.error &&
                                      item.missing_prime_statut != 'Payé' &&
                                      checkPermission('GAPTHVFI') &&
                                      !item.email_sent_at &&
                                      mp.FactureAvoir == 0
                                  "
                                  :updateFunction="
                                    uploadObligeeTemplateInServieVisionFiliale
                                  "
                                  :getTemplateFunction="
                                    getObligeeTemplateAndFiledsDataToUpdateForFcature
                                  "
                                  :refereshTemplateFunction="
                                    refreshFactureDossier
                                  "
                                  :downloadFactureApi="
                                    downloadFactureObligeeItem
                                  "
                                  :download="mp.id"
                                  :showDownloadButton="
                                    checkPermission('GAPTHTFIR')
                                  "
                                  :value="mp.total_ht"
                                  :can_reload="item.can_reload"
                                  :visialisation="checkPermission('GAPTHVFO')"
                                  @returnHistorique="returnHistorique"
                                  :body="mp.body"
                                />
                                <template-obligee
                                  type="missing_prime"
                                  type2="avoir"
                                  :item="item"
                                  :updateFunction="uploadObligeAvoir"
                                  :show_generate_facture_file_code="
                                    !item.error &&
                                      item.missing_prime_statut != 'Payé' &&
                                      checkPermission('GAPTHFAP') &&
                                      mp.reste_avoir != 0
                                  "
                                  :getTemplateFunction="
                                    getObligeeTemplateAndFiledsDataToUpdateForFcature
                                  "
                                  :refereshTemplateFunction="
                                    refreshFactureDossier
                                  "
                                  :download="mp.id"
                                  :showDownloadButton="false"
                                  :value="mp.total_ht"
                                  :can_reload="item.can_reload"
                                  IconClass="red-color"
                                  @returnHistorique="returnHistorique"
                                  :filter="ComputedFilter"
                                />
                                <Historique
                                  :awesome="true"
                                  :permission="checkPermission('GAPTHAFIR')"
                                  :dataToUse="mp.logs"
                                  :index="mp.id"
                                  @returnHistorique="returnHistorique"
                                />
                              </div>
                            </div>
                          </b-popover>
                        </div>
                        <div>
                          <template-obligee
                            v-if="
                              !item.error_vendeur &&
                                !item.error_acheteur &&
                                !item.error_group
                            "
                            type="missing_prime"
                            type2="non-avoir"
                            :show_generate_facture_file_code="
                              !item.error &&
                                item.missing_prime_statut != 'Payé' &&
                                checkPermission('GAPTHVFI')
                            "
                            :item="item"
                            :updateFunction="
                              uploadObligeeTemplateInServieVisionFiliale
                            "
                            :getTemplateFunction="
                              getObligeeTemplateAndFiledsDataToUpdate
                            "
                            :refereshTemplateFunction="refreshFactureDossier"
                            :download="
                              item.missing_prime_factures.map(i => i.id)
                            "
                            :showDownloadButton="
                              checkPermission('GAPTHTFIR') &&
                                item.missing_prime_factures.length
                            "
                            :value="item.missing_prime_ht"
                            :can_reload="item.can_reload"
                            :downloadFactureApi="downloadMultiAvoirPdfs"
                          />
                        </div>
                      </div>
                    </td>
                    <template v-if="computedShowSubRow('missing_prime')">
                      <td class="item-details-style">-</td>

                      <td class="item-details-style">
                        <span :class="{ 'red-color': item.warning }">
                          {{ item.missing_prime_ttc }} €</span
                        >
                      </td>
                      <td class="item-details-style">
                        <span :class="{ 'red-color': item.warning }">
                          {{ item.missing_prime_tva }} €</span
                        >
                      </td>
                      <td class="item-details-style">
                        {{ item.missing_prime_num_facture }}
                      </td></template
                    >
                    <td
                      class="item-details-style col-montant-obligee"
                      v-if="checkPermission('GAPTHACAI')"
                    >
                      <div class="center-th-montant">
                        <div>
                          <div class="tooltip-position">
                            <div
                              v-show="
                                item &&
                                  item.missing_prime_factures &&
                                  item.missing_prime_factures.length
                              "
                            >
                              <font-awesome-icon
                                :id="'tooltip-avoir-messing-prime-list' + index"
                                icon="info"
                                class="
                                  info-icon-commercial-lot
                                  content-tooltip-style
                                  w-25
                                "
                              />
                            </div>
                            <div class="margin-text-tooltip">
                              <!-- <mail-component :lot="item.name" /> -->
                              <p class="text-avoir-color ml-1">
                                {{ item.missing_prime_avoir }} €
                              </p>
                            </div>
                          </div>
                          <b-popover
                            :target="'tooltip-avoir-messing-prime-list' + index"
                            :triggers="computedPopover"
                            :custom-class="computedStyle"
                          >
                            <div class="d-flex align-items-center">
                              <div class="d-inline-block">
                                <b-table-simple class="table-fiche">
                                  <b-tbody>
                                    <b-tr class="title-ligne">
                                      <b-th class="newcolor">Facture</b-th>
                                      <b-th class="newcolor">Avoir</b-th>
                                      <b-th class="newcolor">HT</b-th>
                                      <b-th class="newcolor"></b-th>
                                    </b-tr>
                                    <template
                                      v-for="(fact,
                                      iF) in item.missing_prime_factures.filter(
                                        i => i.FactureAvoir.length
                                      )"
                                      ><template
                                        v-for="(factAv, i) in fact.FactureAvoir"
                                      >
                                        <b-tr
                                          :key="'factAv' + i + iF"
                                          :class="
                                            iF % 2 != 0
                                              ? 'title-ligne'
                                              : 'title-ligne-child'
                                          "
                                        >
                                          <b-td class="newcolor"
                                            >{{ fact.num }}
                                          </b-td>
                                          <b-td class="newcolor"
                                            >{{ factAv.num }}
                                          </b-td>
                                          <b-td class="newcolor"
                                            >{{ factAv.total_ht }} €</b-td
                                          >
                                          <b-td class="newcolor">
                                            <font-awesome-icon
                                              class="mr-2"
                                              v-if="
                                                checkPermission('GAPTHVFAI')
                                              "
                                              icon="eye"
                                              @click="
                                                handleOpenAvoirVusialisationModal(
                                                  factAv
                                                )
                                              "/>
                                            <font-awesome-icon
                                              v-if="
                                                checkPermission('GAPTHTPFAI')
                                              "
                                              icon="arrow-alt-circle-down"
                                              @click="clickButton(factAv.id)"
                                          /></b-td>
                                        </b-tr> </template
                                    ></template> </b-tbody
                                ></b-table-simple>
                              </div>
                            </div>
                          </b-popover>
                        </div>
                      </div>
                    </td>

                    <!-- commission gestion-->
                    <td
                      class="
                        item-details-style
                        justify-content-sb
                        th-montant-obligee
                      "
                      v-if="checkPermission('GAPTHACCG')"
                    >
                      <div class="center-th-montant">
                        <div
                          v-show="
                            item &&
                              item.commission_gestion_factures &&
                              item.commission_gestion_factures.length
                          "
                        >
                          <font-awesome-icon
                            :id="
                              `popover-1-commission-gestion-${data.item.name}-${index}`
                            "
                            icon="info"
                            class="
                              info-icon-commercial-lot
                              content-tooltip-style
                              w-25
                            "
                          />
                        </div>
                        <div class="margin-text-tooltip">
                          <p class="text-avoir-color ml-1">
                            {{ item.commission_gestion_ht }} €
                          </p>
                        </div>

                        <b-popover
                          :target="
                            `popover-1-commission-gestion-${data.item.name}-${index}`
                          "
                          :triggers="computedPopover"
                          :custom-class="computedStyle"
                        >
                          <div
                            v-for="(commission_gestion,
                            i) in item.commission_gestion_factures"
                            :key="i"
                          >
                            <div class="d-flex justify-content-space-between">
                              <span class="mr-2"
                                >{{ commission_gestion.num }},
                                {{ commission_gestion.total_ht }} €</span
                              >
                              <template-obligee
                                v-if="
                                  item.consolidee_energie_invest_factures
                                    .length == 0
                                "
                                type="commission_gestion"
                                type2="non-avoir"
                                :item="item"
                                :show_generate_facture_file_code="
                                  !item.error &&
                                    item.commission_gestion_statut != 'Payé' &&
                                    checkPermission('GAPTHVFCG') &&
                                    !item.email_sent_at &&
                                    commission_gestion.FactureAvoir == 0 &&
                                    item.can_facture_commission_gestion
                                "
                                :updateFunction="
                                  uploadObligeeTemplateInServieVisionFiliale
                                "
                                :getTemplateFunction="
                                  getObligeeTemplateAndFiledsDataToUpdateForFcature
                                "
                                :refereshTemplateFunction="
                                  refreshFactureDossier
                                "
                                :downloadFactureApi="downloadFactureObligeeItem"
                                :download="commission_gestion.id"
                                :showDownloadButton="
                                  checkPermission('GAPTHTFCG')
                                "
                                :value="commission_gestion.total_ht"
                                :can_reload="item.can_reload"
                                :visialisation="checkPermission('GAPTHVFO')"
                                @returnHistorique="returnHistorique"
                                :body="commission_gestion.body"
                              />
                              <template-obligee
                                type="commission_gestion"
                                type2="avoir"
                                :item="item"
                                :updateFunction="uploadObligeAvoir"
                                :show_generate_facture_file_code="
                                  !item.error &&
                                    item.commission_gestion_statut != 'Payé' &&
                                    checkPermission('GAPTHFAP') &&
                                    commission_gestion.reste_avoir != 0 &&
                                    item.can_avoir_commission_gestion
                                "
                                :getTemplateFunction="
                                  getObligeeTemplateAndFiledsDataToUpdateForFcature
                                "
                                :refereshTemplateFunction="
                                  refreshFactureDossier
                                "
                                :download="commission_gestion.id"
                                :showDownloadButton="false"
                                :value="commission_gestion.total_ht"
                                :can_reload="item.can_reload"
                                IconClass="red-color"
                                @returnHistorique="returnHistorique"
                                :filter="ComputedFilter"
                              />
                              <Historique
                                :awesome="true"
                                :permission="checkPermission('GAPTHAHFCG')"
                                :dataToUse="commission_gestion.logs"
                                :index="commission_gestion.id"
                                @returnHistorique="returnHistorique"
                              />
                              <!-- </div> -->
                            </div>
                          </div>
                        </b-popover>

                        <template-obligee
                          v-if="
                            !item.error_vendeur &&
                              !item.error_acheteur &&
                              !item.error_group
                          "
                          type="commission_gestion"
                          type2="non-avoir"
                          :show_generate_facture_file_code="
                            !item.error &&
                              item.commission_gestion_statut != 'Payé' &&
                              checkPermission('GAPTHVFCG') &&
                              item.can_facture_commission_gestion
                          "
                          :item="item"
                          :updateFunction="
                            uploadObligeeTemplateInServieVisionFiliale
                          "
                          :getTemplateFunction="
                            getObligeeTemplateAndFiledsDataToUpdate
                          "
                          :refereshTemplateFunction="refreshFactureDossier"
                          :download="
                            item.commission_gestion_factures.map(i => i.id)
                          "
                          :showDownloadButton="
                            checkPermission('GAPTHTFCG') &&
                              item.commission_gestion_factures.length
                          "
                          :value="item.commission_gestion_ht"
                          :can_reload="item.can_reload"
                          :downloadFactureApi="downloadMultiAvoirPdfs"
                        />
                      </div>
                    </td>
                    <template v-if="computedShowSubRow('commission_gestion')">
                      <td class="item-details-style">-</td>
                      <td class="item-details-style">
                        {{ item.commission_gestion_ttc }} €
                      </td>
                      <td class="item-details-style">
                        {{ item.commission_gestion_tva }} €
                      </td>
                      <td class="item-details-style">
                        {{ item.commission_gestion_num_facture }}
                      </td></template
                    >
                    <td
                      class="item-details-style col-montant-obligee"
                      v-if="checkPermission('GAPTHACACG')"
                    >
                      <div class="center-th-montant">
                        <div>
                          <div class="tooltip-position">
                            <div
                              v-show="
                                item &&
                                  item.commission_gestion_factures &&
                                  item.commission_gestion_factures.length
                              "
                            >
                              <font-awesome-icon
                                :id="`popover-2-${data.item.name}-${index}`"
                                icon="info"
                                class="
                                  info-icon-commercial-lot
                                  content-tooltip-style
                                  w-25
                                "
                              />
                            </div>
                            <div class="margin-text-tooltip">
                              <p class="text-avoir-color ml-1">
                                {{ item.commission_gestion_avoir }} €
                              </p>
                            </div>
                          </div>
                          <b-popover
                            :target="`popover-2-${data.item.name}-${index}`"
                            :triggers="computedPopover"
                            :custom-class="computedStyle"
                          >
                            <div>
                              <div class="d-flex align-items-center">
                                <div class="d-inline-block">
                                  <b-table-simple class="table-fiche">
                                    <b-tbody>
                                      <b-tr class="title-ligne">
                                        <b-th class="newcolor">Facture</b-th>
                                        <b-th class="newcolor">Avoir</b-th>
                                        <b-th class="newcolor">HT</b-th>
                                        <b-th class="newcolor"></b-th>
                                      </b-tr>
                                      <template
                                        v-for="(fact,
                                        iF) in item.commission_gestion_factures.filter(
                                          i => i.FactureAvoir.length
                                        )"
                                        ><template
                                          v-for="(factAv,
                                          i) in fact.FactureAvoir"
                                        >
                                          <b-tr
                                            :key="'factAv' + i + iF"
                                            :class="
                                              iF % 2 != 0
                                                ? 'title-ligne'
                                                : 'title-ligne-child'
                                            "
                                          >
                                            <b-td class="newcolor"
                                              >{{ fact.num }}
                                            </b-td>
                                            <b-td class="newcolor"
                                              >{{ factAv.num }}
                                            </b-td>
                                            <b-td class="newcolor"
                                              >{{ factAv.total_ht }} €</b-td
                                            >
                                            <b-td class="newcolor">
                                              <font-awesome-icon
                                                class="mr-2"
                                                v-if="
                                                  checkPermission('GAPTHVFACG')
                                                "
                                                icon="eye"
                                                @click="
                                                  handleOpenAvoirVusialisationModal(
                                                    factAv
                                                  )
                                                "/>
                                              <font-awesome-icon
                                                v-if="
                                                  checkPermission('GAPTHTPFACG')
                                                "
                                                icon="arrow-alt-circle-down"
                                                @click="clickButton(factAv.id)"
                                            /></b-td>
                                          </b-tr> </template
                                      ></template> </b-tbody
                                  ></b-table-simple>
                                </div>
                              </div>
                            </div>
                          </b-popover>
                        </div>
                      </div>
                    </td>
                    <!-- Commission -->
                    <td
                      class="
                        item-details-style
                        justify-content-sb
                        th-montant-obligee
                      "
                      v-if="checkPermission('GAPTHACC')"
                    >
                      <div class="center-th-montant">
                        <div
                          v-show="
                            item &&
                              item.commission_factures &&
                              item.commission_factures.length
                          "
                        >
                          <font-awesome-icon
                            :id="
                              `popover-1-commission_factures-${data.item.name}-${index}`
                            "
                            icon="info"
                            class="
                              info-icon-commercial-lot
                              content-tooltip-style
                              w-25
                            "
                          />
                        </div>
                        <div class="margin-text-tooltip">
                          <p class="text-avoir-color ml-1">
                            {{ item.commission_ht }} €
                          </p>
                        </div>

                        <b-popover
                          :target="
                            `popover-1-commission_factures-${data.item.name}-${index}`
                          "
                          :triggers="computedPopover"
                          :custom-class="computedStyle"
                        >
                          <div
                            v-for="(commission, i) in item.commission_factures"
                            :key="i"
                          >
                            <div class="d-flex justify-content-space-between">
                              <span class="mr-2"
                                >{{ commission.num }},
                                {{ commission.total_ht }} €</span
                              >
                              <template-obligee
                                v-if="
                                  item.consolidee_energie_invest_factures
                                    .length == 0
                                "
                                type="commission"
                                type2="non-avoir"
                                :item="item"
                                :show_generate_facture_file_code="
                                  !item.error &&
                                    item.commission_statut != 'Payé' &&
                                    checkPermission('GAPTHVFC') &&
                                    !item.email_sent_at &&
                                    commission.FactureAvoir == 0 &&
                                    item.can_facture_commission
                                "
                                :updateFunction="
                                  uploadObligeeTemplateInServieVisionFiliale
                                "
                                :getTemplateFunction="
                                  getObligeeTemplateAndFiledsDataToUpdateForFcature
                                "
                                :refereshTemplateFunction="
                                  refreshFactureDossier
                                "
                                :downloadFactureApi="downloadFactureObligeeItem"
                                :download="commission.id"
                                :showDownloadButton="
                                  checkPermission('GAPTHTFCR')
                                "
                                :value="commission.total_ht"
                                :can_reload="item.can_reload"
                                :visialisation="checkPermission('GAPTHVFO')"
                                @returnHistorique="returnHistorique"
                                :body="commission.body"
                              />
                              <template-obligee
                                type="commission"
                                type2="avoir"
                                :item="item"
                                :updateFunction="uploadObligeAvoir"
                                :show_generate_facture_file_code="
                                  !item.error &&
                                    item.commission_statut != 'Payé' &&
                                    checkPermission('GAPTHGFAC') &&
                                    commission.reste_avoir != 0 &&
                                    item.can_avoir_commission
                                "
                                :getTemplateFunction="
                                  getObligeeTemplateAndFiledsDataToUpdateForFcature
                                "
                                :refereshTemplateFunction="
                                  refreshFactureDossier
                                "
                                :download="commission.id"
                                :showDownloadButton="false"
                                :value="commission.total_ht"
                                :can_reload="item.can_reload"
                                IconClass="red-color"
                                @returnHistorique="returnHistorique"
                                :filter="ComputedFilter"
                              />
                              <Historique
                                :awesome="true"
                                :permission="checkPermission('GAPTHAHFCR')"
                                :dataToUse="commission.logs"
                                :index="commission.id"
                                @returnHistorique="returnHistorique"
                              />
                              <!-- </div> -->
                            </div>
                          </div>
                        </b-popover>

                        <template-obligee
                          v-if="
                            !item.error_vendeur &&
                              !item.error_acheteur &&
                              !item.error_group
                          "
                          type="commission"
                          type2="non-avoir"
                          :show_generate_facture_file_code="
                            !item.error &&
                              item.commission_statut != 'Payé' &&
                              checkPermission('GAPTHVFC') &&
                              item.can_facture_commission
                          "
                          :item="item"
                          :updateFunction="
                            uploadObligeeTemplateInServieVisionFiliale
                          "
                          :getTemplateFunction="
                            getObligeeTemplateAndFiledsDataToUpdate
                          "
                          :refereshTemplateFunction="refreshFactureDossier"
                          :download="item.commission_factures.map(i => i.id)"
                          :showDownloadButton="
                            checkPermission('GAPTHTFCR') &&
                              item.commission_factures.length
                          "
                          :value="item.commission_ht"
                          :can_reload="item.can_reload"
                          :downloadFactureApi="downloadMultiAvoirPdfs"
                        />
                      </div>
                    </td>
                    <template v-if="computedShowSubRow('commission')">
                      <td class="item-details-style">-</td>
                      <td class="item-details-style">
                        {{ item.commission_ttc }} €
                      </td>
                      <td class="item-details-style">
                        {{ item.commission_tva }} €
                      </td>
                      <td class="item-details-style">
                        {{ item.commission_num_facture }}
                      </td></template
                    >
                    <td
                      class="item-details-style col-montant-obligee"
                      v-if="checkPermission('GAPTHACAC')"
                    >
                      <div class="center-th-montant">
                        <div>
                          <div class="tooltip-position">
                            <div
                              v-show="
                                item &&
                                  item.commission_factures &&
                                  item.commission_factures.length
                              "
                            >
                              <font-awesome-icon
                                :id="'tooltip-avoir-commission-list' + index"
                                icon="info"
                                class="
                                  info-icon-commercial-lot
                                  content-tooltip-style
                                  w-25
                                "
                              />
                            </div>
                            <div class="margin-text-tooltip">
                              <!-- <mail-component :lot="item.name" /> -->
                              <p class="text-avoir-color ml-1">
                                {{ item.commission_avoir }} €
                              </p>
                            </div>
                          </div>
                          <b-popover
                            :target="'tooltip-avoir-commission-list' + index"
                            :triggers="computedPopover"
                            :custom-class="computedStyle"
                          >
                            <div class="d-flex align-items-center">
                              <div class="d-inline-block">
                                <b-table-simple class="table-fiche">
                                  <b-tbody>
                                    <b-tr class="title-ligne">
                                      <b-th class="newcolor">Facture</b-th>
                                      <b-th class="newcolor">Avoir</b-th>
                                      <b-th class="newcolor">HT</b-th>
                                      <b-th class="newcolor"></b-th>
                                    </b-tr>
                                    <template
                                      v-for="(fact,
                                      iF) in item.commission_factures.filter(
                                        i => i.FactureAvoir.length
                                      )"
                                      ><template
                                        v-for="(factAv, i) in fact.FactureAvoir"
                                      >
                                        <b-tr
                                          :key="'factAv' + i + iF"
                                          :class="
                                            iF % 2 != 0
                                              ? 'title-ligne'
                                              : 'title-ligne-child'
                                          "
                                        >
                                          <b-td class="newcolor"
                                            >{{ fact.num }}
                                          </b-td>
                                          <b-td class="newcolor"
                                            >{{ factAv.num }}
                                          </b-td>
                                          <b-td class="newcolor"
                                            >{{ factAv.total_ht }} €</b-td
                                          >
                                          <b-td class="newcolor">
                                            <font-awesome-icon
                                              class="mr-2"
                                              v-if="
                                                checkPermission('GAPTHVFAC')
                                              "
                                              icon="eye"
                                              @click="
                                                handleOpenAvoirVusialisationModal(
                                                  factAv
                                                )
                                              "/>
                                            <font-awesome-icon
                                              v-if="
                                                checkPermission('GAPTHTPFAC')
                                              "
                                              icon="arrow-alt-circle-down"
                                              @click="clickButton(factAv.id)"
                                          /></b-td>
                                        </b-tr> </template
                                    ></template> </b-tbody
                                ></b-table-simple>
                              </div>
                            </div>
                          </b-popover>
                        </div>
                      </div>
                    </td>
                    <!-- Surprime -->
                    <td
                      class="
                        item-details-style
                        justify-content-sb
                        th-montant-obligee
                      "
                      v-if="checkPermission('GAPTHACS')"
                    >
                      <div class="center-th-montant">
                        <div
                          v-show="
                            item &&
                              item.surprime_factures &&
                              item.surprime_factures.length
                          "
                        >
                          <font-awesome-icon
                            :id="
                              `popover-1-surprime_factures-${data.item.name}-${index}`
                            "
                            icon="info"
                            class="
                              info-icon-commercial-lot
                              content-tooltip-style
                              w-25
                            "
                          />
                        </div>
                        <div class="margin-text-tooltip">
                          <p class="text-avoir-color ml-1">
                            {{ item.surprime_ht }} €
                          </p>
                        </div>

                        <b-popover
                          :target="
                            `popover-1-surprime_factures-${data.item.name}-${index}`
                          "
                          :triggers="computedPopover"
                          :custom-class="computedStyle"
                        >
                          <div
                            v-for="(surprime, i) in item.surprime_factures"
                            :key="i"
                          >
                            <div class="d-flex justify-content-space-between">
                              <span class="mr-2"
                                >{{ surprime.num }},
                                {{ surprime.total_ht }} €</span
                              >
                              <template-obligee
                                v-if="
                                  item.consolidee_energie_invest_factures
                                    .length == 0
                                "
                                type="surprime"
                                type2="non-avoir"
                                :item="item"
                                :show_generate_facture_file_code="
                                  !item.error &&
                                    item.surprime_statut != 'Payé' &&
                                    checkPermission('GAPTHVFS') &&
                                    !item.email_sent_at &&
                                    surprime.FactureAvoir == 0 &&
                                    item.can_facture_surprime
                                "
                                :updateFunction="
                                  uploadObligeeTemplateInServieVisionFiliale
                                "
                                :getTemplateFunction="
                                  getObligeeTemplateAndFiledsDataToUpdateForFcature
                                "
                                :refereshTemplateFunction="
                                  refreshFactureDossier
                                "
                                :downloadFactureApi="downloadFactureObligeeItem"
                                :download="surprime.id"
                                :showDownloadButton="
                                  checkPermission('GAPTHTFSR')
                                "
                                :value="surprime.total_ht"
                                :can_reload="item.can_reload"
                                :visialisation="checkPermission('GAPTHVFO')"
                                @returnHistorique="returnHistorique"
                                :body="surprime.body"
                              />
                              <template-obligee
                                type="surprime"
                                type2="avoir"
                                :item="item"
                                :updateFunction="uploadObligeAvoir"
                                :show_generate_facture_file_code="
                                  !item.error &&
                                    item.surprime_statut != 'Payé' &&
                                    checkPermission('GAPTHGFAS') &&
                                    surprime.reste_avoir != 0 &&
                                    item.can_avoir_surprime
                                "
                                :getTemplateFunction="
                                  getObligeeTemplateAndFiledsDataToUpdateForFcature
                                "
                                :refereshTemplateFunction="
                                  refreshFactureDossier
                                "
                                :download="surprime.id"
                                :showDownloadButton="false"
                                :value="surprime.total_ht"
                                :can_reload="item.can_reload"
                                IconClass="red-color"
                                @returnHistorique="returnHistorique"
                                :filter="ComputedFilter"
                              />
                              <Historique
                                :awesome="true"
                                :permission="checkPermission('GAPTHAHFSR')"
                                :dataToUse="surprime.logs"
                                :index="surprime.id"
                                @returnHistorique="returnHistorique"
                              />
                              <!-- </div> -->
                            </div>
                          </div>
                        </b-popover>

                        <template-obligee
                          v-if="
                            !item.error_vendeur &&
                              !item.error_acheteur &&
                              !item.error_group
                          "
                          type="surprime"
                          type2="non-avoir"
                          :show_generate_facture_file_code="
                            !item.error &&
                              item.surprime_statut != 'Payé' &&
                              checkPermission('GAPTHVFS') &&
                              item.can_facture_surprime
                          "
                          :item="item"
                          :updateFunction="
                            uploadObligeeTemplateInServieVisionFiliale
                          "
                          :getTemplateFunction="
                            getObligeeTemplateAndFiledsDataToUpdate
                          "
                          :refereshTemplateFunction="refreshFactureDossier"
                          :download="item.surprime_factures.map(i => i.id)"
                          :showDownloadButton="
                            checkPermission('GAPTHTFSR') &&
                              item.surprime_factures.length
                          "
                          :value="item.surprime_ht"
                          :can_reload="item.can_reload"
                          :downloadFactureApi="downloadMultiAvoirPdfs"
                        />
                      </div>
                    </td>
                    <template v-if="computedShowSubRow('surprime')">
                      <td class="item-details-style">-</td>
                      <td class="item-details-style">
                        {{ item.surprime_ttc }} €
                      </td>
                      <td class="item-details-style">
                        {{ item.surprime_tva }} €
                      </td>
                      <td class="item-details-style">
                        {{ item.surprime_num_facture }}
                      </td></template
                    >
                    <td
                      class="item-details-style col-montant-obligee"
                      v-if="checkPermission('GAPTHACAS')"
                    >
                      <div class="center-th-montant">
                        <div>
                          <div class="tooltip-position">
                            <div
                              v-show="
                                item &&
                                  item.surprime_factures &&
                                  item.surprime_factures.length
                              "
                            >
                              <font-awesome-icon
                                :id="'tooltip-avoir-surprime-list' + index"
                                icon="info"
                                class="
                                  info-icon-commercial-lot
                                  content-tooltip-style
                                  w-25
                                "
                              />
                            </div>
                            <div class="margin-text-tooltip">
                              <!-- <mail-component :lot="item.name" /> -->
                              <p class="text-avoir-color ml-1">
                                {{ item.surprime_avoir }} €
                              </p>
                            </div>
                          </div>
                          <b-popover
                            :target="'tooltip-avoir-surprime-list' + index"
                            :triggers="computedPopover"
                            :custom-class="computedStyle"
                          >
                            <div class="d-flex align-items-center">
                              <div class="d-inline-block">
                                <b-table-simple class="table-fiche">
                                  <b-tbody>
                                    <b-tr class="title-ligne">
                                      <b-th class="newcolor">Facture</b-th>
                                      <b-th class="newcolor">Avoir</b-th>
                                      <b-th class="newcolor">HT</b-th>
                                      <b-th class="newcolor"></b-th>
                                    </b-tr>
                                    <template
                                      v-for="(fact,
                                      iF) in item.surprime_factures.filter(
                                        i => i.FactureAvoir.length
                                      )"
                                      ><template
                                        v-for="(factAv, i) in fact.FactureAvoir"
                                      >
                                        <b-tr
                                          :key="'factAv' + i + iF"
                                          :class="
                                            iF % 2 != 0
                                              ? 'title-ligne'
                                              : 'title-ligne-child'
                                          "
                                        >
                                          <b-td class="newcolor"
                                            >{{ fact.num }}
                                          </b-td>
                                          <b-td class="newcolor"
                                            >{{ factAv.num }}
                                          </b-td>
                                          <b-td class="newcolor"
                                            >{{ factAv.total_ht }} €</b-td
                                          >
                                          <b-td class="newcolor">
                                            <font-awesome-icon
                                              class="mr-2"
                                              v-if="
                                                checkPermission('GAPTHVFAS')
                                              "
                                              icon="eye"
                                              @click="
                                                handleOpenAvoirVusialisationModal(
                                                  factAv
                                                )
                                              "/>
                                            <font-awesome-icon
                                              v-if="
                                                checkPermission('GAPTHTPFAS')
                                              "
                                              icon="arrow-alt-circle-down"
                                              @click="clickButton(factAv.id)"
                                          /></b-td>
                                        </b-tr> </template
                                    ></template> </b-tbody
                                ></b-table-simple>
                              </div>
                            </div>
                          </b-popover>
                        </div>
                      </div>
                    </td>
                    <!-- Factures consolide -->
                    <td
                      class="
                        item-details-style
                        justify-content-sb
                        th-montant-obligee
                      "
                      v-if="checkPermission('GAPTHACFCEI')"
                    >
                      <div class="center-th-montant">
                        <div
                          v-show="
                            item &&
                              item.consolidee_energie_invest_factures &&
                              item.consolidee_energie_invest_factures.length
                          "
                        >
                          <font-awesome-icon
                            :id="
                              `popover-1-consolidee-${data.item.name}-${index}`
                            "
                            icon="info"
                            class="
                              info-icon-commercial-lot
                              content-tooltip-style
                              w-25
                            "
                          />
                        </div>
                        <div class="margin-text-tooltip">
                          <p class="text-avoir-color ml-1">
                            {{ item.consolidee_energie_invest_ht }} €
                          </p>
                        </div>

                        <b-popover
                          :target="
                            `popover-1-consolidee-${data.item.name}-${index}`
                          "
                          :triggers="computedPopover"
                          :custom-class="computedStyle"
                        >
                          <div
                            v-for="(consolidee_energie_invest,
                            i) in item.consolidee_energie_invest_factures"
                            :key="i"
                          >
                            <div class="d-flex justify-content-space-between">
                              <span class="mr-2"
                                >{{ consolidee_energie_invest.num }},
                                {{ consolidee_energie_invest.total_ht }} €</span
                              >
                              <template-obligee
                                type="consolidee_energie_invest"
                                type2="non-avoir"
                                :item="item"
                                :show_generate_facture_file_code="
                                  !item.error &&
                                    item.consolidee_energie_invest_statut !=
                                      'Payé' &&
                                    checkPermission('GAPTHVFCEI') &&
                                    !item.email_sent_at &&
                                    consolidee_energie_invest.FactureAvoir ==
                                      0 &&
                                    item.can_facture_consolidee_energie_invest
                                "
                                :updateFunction="
                                  uploadObligeeTemplateInServieVisionFiliale
                                "
                                :getTemplateFunction="
                                  getObligeeTemplateAndFiledsDataToUpdateForFcature
                                "
                                :refereshTemplateFunction="
                                  refreshFactureDossier
                                "
                                :downloadFactureApi="downloadFactureObligeeItem"
                                :download="consolidee_energie_invest.id"
                                :showDownloadButton="
                                  checkPermission('GAPTHTFCEI')
                                "
                                :value="consolidee_energie_invest.total_ht"
                                :can_reload="item.can_reload"
                                :visialisation="checkPermission('GAPTHVFO')"
                                @returnHistorique="returnHistorique"
                                :body="consolidee_energie_invest.body"
                              />
                              <template-obligee
                                type="consolidee_energie_invest"
                                type2="avoir"
                                :item="item"
                                :updateFunction="uploadObligeAvoir"
                                :show_generate_facture_file_code="
                                  !item.error &&
                                    item.consolidee_energie_invest_statut !=
                                      'Payé' &&
                                    checkPermission('GAPTHTPFACEI') &&
                                    consolidee_energie_invest.reste_avoir !=
                                      0 &&
                                    item.can_avoir_consolidee_energie_invest
                                "
                                :getTemplateFunction="
                                  getObligeeTemplateAndFiledsDataToUpdateForFcature
                                "
                                :refereshTemplateFunction="
                                  refreshFactureDossier
                                "
                                :download="consolidee_energie_invest.id"
                                :showDownloadButton="false"
                                :value="consolidee_energie_invest.total_ht"
                                :can_reload="item.can_reload"
                                IconClass="red-color"
                                @returnHistorique="returnHistorique"
                                :filter="ComputedFilter"
                              />
                              <Historique
                                :awesome="true"
                                :permission="checkPermission('GAPTHAHCEI')"
                                :dataToUse="consolidee_energie_invest.logs"
                                :index="consolidee_energie_invest.id"
                                @returnHistorique="returnHistorique"
                              />
                              <!-- </div> -->
                            </div>
                          </div>
                        </b-popover>

                        <template-obligee
                          v-if="
                            !item.error_vendeur &&
                              !item.error_acheteur &&
                              !item.error_group
                          "
                          type="consolidee_energie_invest"
                          type2="non-avoir"
                          :show_generate_facture_file_code="
                            !item.error &&
                              item.consolidee_energie_invest_statut != 'Payé' &&
                              checkPermission('GAPTHVFCEI') &&
                              item.can_facture_consolidee_energie_invest
                          "
                          :item="item"
                          :updateFunction="
                            uploadObligeeTemplateInServieVisionFiliale
                          "
                          :getTemplateFunction="
                            getObligeeTemplateAndFiledsDataToUpdate
                          "
                          :refereshTemplateFunction="refreshFactureDossier"
                          :download="
                            item.consolidee_energie_invest_factures.map(
                              i => i.id
                            )
                          "
                          :showDownloadButton="
                            checkPermission('GAPTHTPFACEI') &&
                              item.consolidee_energie_invest_factures.length
                          "
                          :value="item.consolidee_energie_invest_ht"
                          :can_reload="item.can_reload"
                          :downloadFactureApi="downloadMultiAvoirPdfs"
                        />
                      </div>
                    </td>
                    <template
                      v-if="computedShowSubRow('consolidee_energie_invest')"
                    >
                      <td class="item-details-style">-</td>
                      <td class="item-details-style">
                        {{ item.consolidee_energie_invest_ttc }} €
                      </td>
                      <td class="item-details-style">
                        {{ item.consolidee_energie_invest_tva }} €
                      </td>
                      <td class="item-details-style">
                        {{ item.consolidee_energie_invest_num_facture }}
                      </td></template
                    >
                    <td
                      class="item-details-style col-montant-obligee"
                      v-if="checkPermission('GAPTHACACEI')"
                    >
                      <div class="center-th-montant">
                        <div>
                          <div class="tooltip-position">
                            <div
                              v-show="
                                item &&
                                  item.consolidee_energie_invest_factures &&
                                  item.consolidee_energie_invest_factures.length
                              "
                            >
                              <font-awesome-icon
                                :id="`popover-1-${index}`"
                                icon="info"
                                class="
                                  info-icon-commercial-lot
                                  content-tooltip-style
                                  w-25
                                "
                              />
                            </div>
                            <div class="margin-text-tooltip">
                              <p class="text-avoir-color ml-1">
                                {{ item.consolidee_energie_invest_avoir }} €
                              </p>
                            </div>
                          </div>
                          <b-popover
                            :target="`popover-1-${index}`"
                            :triggers="computedPopover"
                            :custom-class="computedStyle"
                          >
                            <div class="d-flex align-items-center">
                              <div class="d-inline-block">
                                <b-table-simple class="table-fiche">
                                  <b-tbody>
                                    <b-tr class="title-ligne">
                                      <b-th class="newcolor">Facture</b-th>
                                      <b-th class="newcolor">Avoir</b-th>
                                      <b-th class="newcolor">HT</b-th>
                                      <b-th class="newcolor"></b-th>
                                    </b-tr>
                                    <template
                                      v-for="(fact,
                                      iF) in item.consolidee_energie_invest_factures.filter(
                                        i => i.FactureAvoir.length
                                      )"
                                      ><template
                                        v-for="(factAv, i) in fact.FactureAvoir"
                                      >
                                        <b-tr
                                          :key="'factAv' + i + iF"
                                          :class="
                                            iF % 2 != 0
                                              ? 'title-ligne'
                                              : 'title-ligne-child'
                                          "
                                        >
                                          <b-td class="newcolor"
                                            >{{ fact.num }}
                                          </b-td>
                                          <b-td class="newcolor"
                                            >{{ factAv.num }}
                                          </b-td>
                                          <b-td class="newcolor"
                                            >{{ factAv.total_ht }} €</b-td
                                          >
                                          <b-td class="newcolor">
                                            <font-awesome-icon
                                              class="mr-2"
                                              v-if="
                                                checkPermission('GAPTHVFACEI')
                                              "
                                              icon="eye"
                                              @click="
                                                handleOpenAvoirVusialisationModal(
                                                  factAv
                                                )
                                              "/>
                                            <font-awesome-icon
                                              v-if="
                                                checkPermission('GAPTHTPFACEI')
                                              "
                                              icon="arrow-alt-circle-down"
                                              @click="clickButton(factAv.id)"
                                          /></b-td>
                                        </b-tr> </template
                                    ></template> </b-tbody
                                ></b-table-simple>
                              </div>
                            </div>
                          </b-popover>
                        </div>
                      </div>
                    </td>
                    <td class="item-details-style td-total-coef-oblige">
                      <!-- {{ computedTotalDepot(item) }} € -->
                      -
                    </td></span
                  >
                </template>
                <template v-if="item.showDetails == true">
                  <span
                    v-for="(itemD, index) in item.projects"
                    :key="'depot' + itemD + index"
                    class="align-td-item border-botom background-depot"
                    :class="{ ligneAnomalieObligee: itemD.error == true }"
                  >
                    <td class="item-details-style col-check-obligee">
                      {{ itemD.error }}
                      <b-form-checkbox
                        v-model="itemD.check"
                        @change="
                          checkAllVerifDetailsDepot(
                            data.item,
                            item,
                            itemD,
                            index
                          )
                        "
                        class="check-th-details ml-2"
                        :value="true"
                        :unchecked-value="false"
                      >
                      </b-form-checkbox>
                    </td>
                    <td class="item-details-style col-plus-obligee">
                      <span
                        ><font-awesome-icon icon="info" class="icon-info-show"
                      /></span>
                    </td>
                    <td class="item-details-style num-lot-td">
                      <font-awesome-icon
                        icon="lock-open"
                        class="mr-1"
                        v-if="itemD.montant_min_lock == false"
                      />
                      <font-awesome-icon
                        icon="lock"
                        class="mr-1"
                        v-if="itemD.montant_min_lock == true"
                      />
                      {{ itemD.numero_dossier }}
                    </td>
                    <td class="item-details-style">
                      {{ itemD.nom1 }} {{ itemD.prenom1 }}
                    </td>
                    <td class="item-details-style">
                      {{ itemD.organisme }}
                    </td>
                    <td class="item-details-style">
                      {{ itemD.reste_a_charge }}
                    </td>
                    <td class="item-details-style">
                      {{ itemD.montant_ttc_avoir }}
                    </td>
                    <td class="item-details-style">
                      {{ itemD.montant_total_ttc }} €
                    </td>
                    <td class="item-details-style">
                      <EditableInputSuiviTh
                        :editable="checkPermission('GAPTHMMPC')"
                        champName="montant_prime_cee"
                        :item="itemD"
                        :value="itemD.montant_prime_cee"
                        type="text"
                        :updateFunction="updateSuiviPaiementDataTh"
                        valueToShow="montant_prime_cee"
                        :showIconEdit="checkPermission('GAPTHMMPC')"
                        :data="item"
                      />
                    </td>
                    <td class="item-details-style">
                      <EditableInputSuiviTh
                        :editable="checkPermission('GAPTHMKC')"
                        champName="kwh_cumac"
                        :item="itemD"
                        :value="itemD.kwh_cumac"
                        type="text"
                        :updateFunction="updateSuiviPaiementDataTh"
                        valueToShow="kwh_cumac"
                        :showIconEdit="checkPermission('GAPTHMKC')"
                        :data="item"
                      />
                    </td>
                    <td class="item-details-style">{{ itemD.m_cumac }}</td>
                    <td class="item-details-style">
                      <EditableInputSuiviTh
                        :editable="checkPermission('GAPTHMCPIPU')"
                        champName="coef_prime_installateur"
                        :item="itemD"
                        :value="itemD.coef_prime_installateur"
                        type="text"
                        :updateFunction="updateSuiviPaiementDataTh"
                        valueToShow="coef_prime_installateur"
                        :showIconEdit="checkPermission('GAPTHMCPIPU')"
                        :data="item"
                      />
                    </td>
                    <!-- prime  -->
                    <td
                      class="item-details-style th-montant-obligee"
                      v-if="checkPermission('GAPTHACP')"
                    >
                      {{ itemD.prime_ht }}
                    </td>
                    <template v-if="computedShowSubRow('prime')">
                      <td class="item-details-style">
                        <template v-if="itemD.reste_a_charge > 1">
                          <EditableInputSuiviTh
                            :editable="computedCantUpdate"
                            champName="prime_pu"
                            :item="itemD"
                            :value="itemD.prime_pu"
                            type="text"
                            :updateFunction="updateSuiviPaiementDataTh"
                            valueToShow="prime_pu"
                            :showIconEdit="true"
                            :data="item"
                          />
                        </template>
                        <template v-else> {{ itemD.prime_pu }} </template>
                      </td>
                      <td class="item-details-style">
                        {{ itemD.prime_ttc }} €
                      </td>
                      <td class="item-details-style">
                        {{ itemD.prime_tva }} €
                      </td>
                      <!-- <td class="item-details-style">-</td> -->
                      <td class="item-details-style">-</td></template
                    >
                    <td
                      class="item-details-style"
                      v-if="checkPermission('GAPTHACAP')"
                    >
                      -
                    </td>
                    <!-- missing prime  -->
                    <td
                      class="item-details-style th-montant-obligee"
                      v-if="checkPermission('GAPTHACI')"
                    >
                      <span :class="{ 'red-color': itemD.warning }">
                        {{ itemD.missing_prime_ht }} €</span
                      >
                    </td>
                    <template v-if="computedShowSubRow('missing_prime')">
                      <td class="item-details-style">
                        {{ itemD.missing_prime_pu }}
                      </td>

                      <td class="item-details-style">
                        <span :class="{ 'red-color': itemD.warning }">
                          {{ itemD.missing_prime_ttc }} €</span
                        >
                      </td>

                      <td class="item-details-style">
                        <span :class="{ 'red-color': itemD.warning }">
                          {{ itemD.missing_prime_tva }} €</span
                        >
                      </td>

                      <!-- <td class="item-details-style">-</td> -->
                      <td class="item-details-style">-</td></template
                    >
                    <td
                      class="item-details-style"
                      v-if="checkPermission('GAPTHACAI')"
                    >
                      -
                    </td>
                    <!-- commission gestion -->
                    <td
                      class="item-details-style th-montant-obligee"
                      v-if="checkPermission('GAPTHACCG')"
                    >
                      {{ itemD.commission_gestion_ht }} €
                    </td>
                    <template v-if="computedShowSubRow('commission_gestion')">
                      <td class="item-details-style">
                        <EditableInputSuiviTh
                          :editable="
                            computedCantUpdate &&
                              item.commission_gestion_statut != 'Payé'
                          "
                          champName="commission_gestion_pu"
                          :item="itemD"
                          :value="itemD.commission_gestion_pu"
                          type="text"
                          :updateFunction="updateSuiviPaiementDataTh"
                          valueToShow="commission_gestion_pu"
                          :showIconEdit="true"
                          :data="item"
                        />
                      </td>
                      <td class="item-details-style">
                        {{ itemD.commission_gestion_ttc }} €
                      </td>
                      <td class="item-details-style">
                        {{ itemD.commission_gestion_tva }} €
                      </td>
                      <!-- <td class="item-details-style">-</td> -->
                      <td class="item-details-style">-</td>
                    </template>
                    <td
                      class="item-details-style"
                      v-if="checkPermission('GAPTHACACG')"
                    >
                      -
                    </td>
                    <!-- commision -->
                    <td
                      class="item-details-style th-montant-obligee"
                      v-if="checkPermission('GAPTHACAC')"
                    >
                      {{ itemD.commission_ht }} €
                    </td>
                    <template v-if="computedShowSubRow('commission')">
                      <td class="item-details-style">
                        <EditableInputSuiviTh
                          :editable="
                            computedCantUpdate &&
                              item.commission_statut != 'Payé'
                          "
                          champName="commission_pu"
                          :item="itemD"
                          :value="itemD.commission_pu"
                          type="text"
                          :updateFunction="updateSuiviPaiementDataTh"
                          valueToShow="commission_pu"
                          :showIconEdit="true"
                          :data="item"
                        />
                      </td>
                      <td class="item-details-style">
                        {{ itemD.commission_ttc }} €
                      </td>
                      <td class="item-details-style">
                        {{ itemD.commission_tva }} €
                      </td>
                      <!-- <td class="item-details-style b">-</td> -->
                      <td class="item-details-style">-</td>
                    </template>
                    <td
                      class="item-details-style"
                      v-if="checkPermission('GAPTHACAC')"
                    >
                      -
                    </td>
                    <!-- surprime -->
                    <td
                      class="item-details-style th-montant-obligee"
                      v-if="checkPermission('GAPTHACAS')"
                    >
                      {{ itemD.surprime_ht }} €
                    </td>
                    <template v-if="computedShowSubRow('surprime')">
                      <td class="item-details-style">
                        <EditableInputSuiviTh
                          :editable="
                            computedCantUpdate && item.surprime_statut != 'Payé'
                          "
                          champName="surprime_pu"
                          :item="itemD"
                          :value="itemD.surprime_pu"
                          type="text"
                          :updateFunction="updateSuiviPaiementDataTh"
                          valueToShow="surprime_pu"
                          :showIconEdit="true"
                          :data="item"
                        />
                      </td>
                      <td class="item-details-style">
                        {{ itemD.surprime_ttc }} €
                      </td>
                      <td class="item-details-style">
                        {{ itemD.surprime_tva }} €
                      </td>
                      <!-- <td class="item-details-style">-</td> -->
                      <td class="item-details-style">-</td>
                    </template>
                    <td
                      class="item-details-style"
                      v-if="checkPermission('GAPTHACAS')"
                    >
                      -
                    </td>

                    <!-- consolide energie invest -->
                    <td
                      class="item-details-style th-montant-obligee"
                      v-if="checkPermission('GAPTHACACEI')"
                    >
                      {{ itemD.consolidee_energie_invest_ht }} €
                    </td>
                    <template
                      v-if="computedShowSubRow('consolidee_energie_invest')"
                    >
                      <td class="item-details-style">
                        <EditableInputSuiviTh
                          :editable="
                            computedCantUpdate &&
                              item.consolidee_energie_invest_statut != 'Payé'
                          "
                          champName="consolidee_energie_invest_pu"
                          :item="itemD"
                          :value="itemD.consolidee_energie_invest_pu"
                          type="text"
                          :updateFunction="updateSuiviPaiementDataTh"
                          valueToShow="consolidee_energie_invest_pu"
                          :showIconEdit="true"
                          :data="item"
                        />
                      </td>
                      <td class="item-details-style">
                        {{ itemD.consolidee_energie_invest_ttc }} €
                      </td>
                      <td class="item-details-style">
                        {{ itemD.consolidee_energie_invest_tva }} €
                      </td>
                      <!-- <td class="item-details-style">-</td> -->
                      <td class="item-details-style">-</td>
                    </template>
                    <td
                      class="item-details-style"
                      v-if="checkPermission('GAPTHACACEI')"
                    >
                      -
                    </td>
                    <td class="item-details-style td-total-coef-oblige">
                      <!-- {{ computedTotal(itemD) }} € -->
                      {{ itemD.total_coef }}
                    </td>
                  </span>
                </template>
              </span>
            </template>
          </b-table>
          <div
            v-if="computedTotalProjectSelected > 0"
            class="d-flex justify-content-center"
          >
            <b-button
              size="sm"
              class="
                button-export-style
                ml-2
                pl-2
                pr-2
                new-color-export-facture
              "
              :title="
                showaTableSum
                  ? 'Masquer le tableau de sommation'
                  : 'Afficher le tableau de sommation'
              "
              @click="showaTableSum = !showaTableSum"
            >
              <font-awesome-icon
                :icon="showaTableSum ? 'arrow-down' : 'arrow-up'"
            /></b-button>
          </div>
          <div v-if="computedTotalProjectSelected > 0 && showaTableSum == true">
            <b-table-simple
              class="table-fiche custom-table-style mb-0"
              responsive
            >
              <b-tbody>
                <b-tr class="title-ligne">
                  <b-th class="newcolor">NB dossier</b-th>
                  <b-th class="newcolor">Reste à charge</b-th>
                  <b-th class="newcolor">Montant TTC de l'avoir</b-th>
                  <b-th class="newcolor">Montant Total TTC</b-th>
                  <b-th class="newcolor">Montant Prime CEE </b-th>
                  <b-th class="newcolor">Kwh cumac </b-th>
                  <b-th class="newcolor">Mega cumac </b-th>
                  <b-th
                    class="newcolor background-td-prim"
                    v-if="checkPermission('GAPTHACP')"
                    >Prime Installateur
                  </b-th>

                  <b-th
                    class="newcolor background-td-prim"
                    v-if="checkPermission('GAPTHACP')"
                    >Prime Installateur TTC
                  </b-th>
                  <b-th
                    class="newcolor background-td-prim"
                    v-if="checkPermission('GAPTHACAP')"
                    >Prime avoir
                  </b-th>
                  <b-th
                    class="newcolor background-td-comm"
                    v-if="checkPermission('GAPTHACI')"
                    >Comm Installateur
                  </b-th>
                  <b-th
                    class="newcolor background-td-comm"
                    v-if="checkPermission('GAPTHACI')"
                    >Comm Installateur TTC
                  </b-th>
                  <b-th
                    class="newcolor background-td-comm"
                    v-if="checkPermission('GAPTHACI')"
                    >Comm Installateur TVA
                  </b-th>
                  <b-th
                    class="newcolor background-td-comm"
                    v-if="checkPermission('GAPTHACAI')"
                    >Comm avoir
                  </b-th>
                  <b-th
                    class="newcolor commission-gestion-bg"
                    v-if="checkPermission('GAPTHACCG')"
                    >Commission gestion HT
                  </b-th>
                  <b-th
                    class="newcolor commission-gestion-bg"
                    v-if="checkPermission('GAPTHACCG')"
                    >Commission gestion TTC</b-th
                  >
                  <b-th
                    class="newcolor commission-gestion-bg"
                    v-if="checkPermission('GAPTHACCG')"
                    >Commission gestion TVA</b-th
                  >
                  <b-th
                    class="newcolor commission-gestion-bg"
                    v-if="checkPermission('GAPTHFACG')"
                    >Commission gestion avoir</b-th
                  >
                  <b-th
                    class="newcolor background-td-commission"
                    v-if="checkPermission('GAPTHACC')"
                    >Commission
                  </b-th>
                  <b-th
                    class="newcolor background-td-commission"
                    v-if="checkPermission('GAPTHACC')"
                    >Commission TTC</b-th
                  >
                  <b-th
                    class="newcolor background-td-commission"
                    v-if="checkPermission('GAPTHACC')"
                    >Commission TVA</b-th
                  >
                  <b-th
                    class="newcolor background-td-commission"
                    v-if="checkPermission('GAPTHACAC')"
                    >Commission avoir</b-th
                  >
                  <b-th
                    class="newcolor background-td-suprim"
                    v-if="checkPermission('GAPTHACS')"
                    >Surprime HT
                  </b-th>
                  <b-th
                    class="newcolor background-td-suprim"
                    v-if="checkPermission('GAPTHACS')"
                    >Surprime TTC</b-th
                  >
                  <b-th
                    class="newcolor background-td-suprim"
                    v-if="checkPermission('GAPTHACS')"
                    >Surprime TVA</b-th
                  >
                  <b-th
                    class="newcolor background-td-suprim"
                    v-if="checkPermission('GAPTHACAS')"
                    >Surprime avoir</b-th
                  >
                  <b-th
                    class="newcolor consolidee-td-energie-invest"
                    v-if="checkPermission('GAPTHACFCEI')"
                    >Consolidée Energie Invest HT</b-th
                  >
                  <b-th
                    class="newcolor consolidee-td-energie-invest"
                    v-if="checkPermission('GAPTHACFCEI')"
                    >Consolidée Energie Invest TVA</b-th
                  >
                  <b-th
                    class="newcolor consolidee-td-energie-invest"
                    v-if="checkPermission('GAPTHACFCEI')"
                    >Consolidée Energie Invest TTC</b-th
                  >
                  <b-th
                    class="newcolor consolidee-td-energie-invest"
                    v-if="checkPermission('GAPTHACACEI')"
                    >Consolidée Energie Invest avoir</b-th
                  >
                </b-tr>

                <b-tr>
                  <b-td class="">{{ computedTotalProjectSelected }}</b-td>
                  <b-td>{{ computedRestAcharge }}€</b-td>
                  <b-td>{{ computedMontantTtcAvoir }}€</b-td>
                  <b-td>{{ computedMontantTotalTtc }}€</b-td>
                  <b-td>{{ computedMontantPrimeCee }}€</b-td>
                  <b-td>{{ computedMontantkwh }}€</b-td>
                  <b-td>{{ computedMontantMegaCumac }}€</b-td>
                  <b-td v-if="checkPermission('GAPTHACP')"
                    >{{ computedMontantPrimeInstallateur }}€</b-td
                  >
                  <b-td v-if="checkPermission('GAPTHACP')"
                    >{{ computedMontantPrimeInstallateurTTC }}€</b-td
                  >
                  <b-td v-if="checkPermission('GAPTHACAP')"
                    >{{ computedMontantPrimeAvoir }} €</b-td
                  >
                  <b-td v-if="checkPermission('GAPTHACI')"
                    >{{ computedMontantCommInstallateur }}€</b-td
                  >
                  <b-td v-if="checkPermission('GAPTHACI')"
                    >{{ computedMontantCommInstallateurTTC }}€</b-td
                  >
                  <b-td v-if="checkPermission('GAPTHACI')"
                    >{{ computedMontantCommInstallateurTVA }}€</b-td
                  >
                  <b-td v-if="checkPermission('GAPTHACAI')"
                    >{{ computedMontantMissingPrimeAvoir }} €</b-td
                  >
                  <b-td v-if="checkPermission('GAPTHACCG')"
                    >{{ computedMontantCommissionGestion }}€</b-td
                  >
                  <b-td v-if="checkPermission('GAPTHACCG')"
                    >{{ computedMontantCommissionGestionTTC }}€</b-td
                  >
                  <b-td v-if="checkPermission('GAPTHACCG')"
                    >{{ computedMontantCommissionGestionTVA }}€</b-td
                  >
                  <b-td v-if="checkPermission('GAPTHACACG')"
                    >{{ computedMontantCommissionGestionAvoir }} €</b-td
                  >
                  <b-td v-if="checkPermission('GAPTHACC')"
                    >{{ computedMontantCommission }}€</b-td
                  >
                  <b-td v-if="checkPermission('GAPTHACC')"
                    >{{ computedMontantCommissionTTC }}€</b-td
                  >
                  <b-td v-if="checkPermission('GAPTHACC')"
                    >{{ computedMontantCommissionTVA }}€</b-td
                  >
                  <b-td v-if="checkPermission('GAPTHACAC')"
                    >{{ computedMontantCommissionAvoir }} €</b-td
                  >
                  <b-td v-if="checkPermission('GAPTHACS')"
                    >{{ computedMontantSurprime }} €</b-td
                  >
                  <b-td v-if="checkPermission('GAPTHACS')"
                    >{{ computedMontantSurprimeTTC }} €</b-td
                  >
                  <b-td v-if="checkPermission('GAPTHACS')"
                    >{{ computedMontantSurprimeTVA }} €</b-td
                  >
                  <b-td v-if="checkPermission('GAPTHACAS')"
                    >{{ computedMontantSurprimeAvoir }} €</b-td
                  >
                  <b-td v-if="checkPermission('GAPTHACFCEI')">
                    {{ computedMontantConsolideeEnergieInvest }} €
                  </b-td>
                  <b-td v-if="checkPermission('GAPTHACFCEI')">
                    {{ computedMontantConsolideeEnergieInvestTVA }} €
                  </b-td>
                  <b-td v-if="checkPermission('GAPTHACFCEI')">
                    {{ computedMontantConsolideeEnergieInvestTTC }} €
                  </b-td>

                  <b-td v-if="checkPermission('GAPTHACACEI')">
                    {{ computedMontantConsolideeEnergieInvestAvoir }} €
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div v-if="this.getAppeliPaiementThTableObligee" class="footer-style">
            <b-pagination
              v-model="page"
              :total-rows="geTtotalRowsThObligee"
              :per-page="per_page"
              aria-controls="my-table"
              pills
              align="center"
              size="sm"
              @change="pagination"
              class="pagination-style"
            ></b-pagination>
            <div class="per-page-element-style">
              <div class="box-label-champ">
                <div class="label-box-style">
                  <span class="title-tabel-obligie">Eléments par page</span>
                </div>
              </div>
              <b-form-select
                v-model="per_page"
                :options="perPageList"
                @change="changePerPage"
                class="b-form-select-new-style bg-select"
              ></b-form-select>
            </div>
          </div>
        </div>
        <!-- <div id="chart">
          <apexchart
            type="line"
            height="100%"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div> -->
      </div>
    </div>
    <!-- facture to upload  template -->
    <b-modal
      no-close-on-backdrop
      ref="uploadPaiementObligeeTemplateModal"
      id="uploadPaiementObligeeTemplateModal"
      size="sm"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap custom-modal-size-upload"
    >
      <div
        class="iconClose"
        @click.prevent="hideModal('uploadPaiementObligeeTemplateModal')"
      >
        <font-awesome-icon icon="times" />
      </div>
      <div class="d-flex justify-content-center" id="hmt_block_id">
        <upload-files :phase="1" />
        <loader v-if="this.getLoadingUploadFileCdg" />
      </div>
    </b-modal>
    <b-modal
      ref="missingOrganisme"
      id="missingOrganisme"
      no-close-on-backdrop
      :hide-footer="true"
      :title="'Dossier sans organisme'"
      @hidden="hideModal('missingOrganisme')"
      modal-class="modal-extaction-bdd-frais"
    >
      <ul>
        <li
          v-for="item in missingOrganismeInObligeeFile"
          :key="'org' + item"
          class="text-missing-organisme-obligee"
        >
          {{ item }}
        </li>
      </ul>
    </b-modal>
    <b-modal
      ref="vusialsationAvoir"
      id="vusialsationAvoir"
      no-close-on-backdrop
      :hide-footer="true"
      :title="'Visualisation Facture'"
      @hidden="hideModal('vusialsationAvoir')"
      size="lg"
      modal-class="modal-extaction-bdd-frais"
    >
      <div
        id="hmt_template_block_id"
        v-if="templateAvoir"
        v-html="templateAvoir"
      ></div>
    </b-modal>
    <b-modal
      ref="validationGlobalModal"
      id="validationGlobalModal"
      :hide-footer="true"
      :hide-header="true"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Confirmation validation des factures</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('validationGlobalModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>

      <form
        @submit.prevent="validationGlobalFactureObligee"
        class="form-modal-custom-style"
      >
        <div class="mt-4 ml-3 col-12">
          <b-form-group
            label="Type de facture:"
            class="input-modal-champ col-12"
          >
            <div v-if="loader_list_facture">
              <Half-circle-spinner
                :animation-duration="1000"
                :size="50"
                :color="'#4d4bac'"
                class="loeder"
              />
            </div>
            <b-form-checkbox
              v-if="!loader_list_facture"
              v-model="checkAllType"
              @change="changeAllCheckTypes"
              class="check-th-details"
              >Tous</b-form-checkbox
            >
            <b-form-checkbox-group
              v-if="!loader_list_facture"
              class="check-th-details"
              id="checkbox-group-1"
              v-model="type"
              :options="getTypeListFactureObligee"
              name="flavour-1"
              stacked
              value-field="value"
              text-field="text"
              @change="
                type.length == getTypeListFactureObligee.length
                  ? (checkAllType = true)
                  : (checkAllType = false)
              "
            ></b-form-checkbox-group>
          </b-form-group>
        </div>
        <div class="messageError" v-if="error">
          <div class="error text-center">{{ error }}</div>
        </div>
        <!-- <div class="mt-4 ml-3">
          <p>
            Êtes-vous sûr de vouloir générer les factures ?
          </p>
        </div> -->
        <div class="actionModel">
          <b-button type="submit" class="button-valide-style mt-1">
            <span> Générer les factures </span>
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import TemplateObligee from './component/templateObligee.vue';
import exportRapportOblige from './exportRapportOblige.vue';
// import VueApexCharts from 'vue-apexcharts';
// import MailComponent from './MailComponent.vue';

export default {
  name: 'Gestions-des-appels-à-paiement-Obligé',

  data() {
    return {
      showPopover: false,
      error: null,
      searchValue: null,
      showaTableSum: false,
      details_prime: false,
      details_commission: false,
      details_missing_prime: false,
      details_surprime: false,
      details_commission_gestion: false,
      details_consolidee_energie_invest: false,
      loadingUpload: false,
      errorUpload: null,
      file: null,
      loader_list_facture: false,
      // optionsSelectStatut: [{ full_name: 'a paye' }, { full_name: 'paye' }],
      optionsSelectStatut: [
        { value: 'A payer', full_name: 'A payer' },
        { value: 'Payé', full_name: 'Payée' }
        // { value: 'Annulée', full_name: 'Annulée' }
      ],
      date_debut: null,
      date_fin: null,
      page: 1,
      per_page: 20,
      filterFiliale: [],
      filterLot: [],
      filterOrganisme: [],
      templateAvoir: null,
      show: true,
      checkAllType: true,
      type: [],
      checkAll: false,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      resetModalExportFacture: false
    };
  },
  methods: {
    ...mapActions([
      'generateFactureMastersObliges',
      'getAppelPaiementObligeeThFiltred',
      'downloadFactureObligeeItem',
      'downloadFactureGlobaleObligeeItem',
      'updateSuiviPaiementDataTh',
      'getObligeeTemplateAndFiledsDataToUpdate',
      'getObligeeTemplateAndFiledsDataToUpdateForFcature',
      'uploadObligeeTemplateInServieVisionFiliale',
      'refreshAooelPaiementObligeeAction',
      'updateCoefObligee',
      'getAllDataForFilterObligee',
      'updateSuiviPaiementStatutTh',
      'downloadPaiementObligeeThFiltredExcel',
      'sendMailVertigo',
      'uploadObligeexls',
      'uploadObligeexlsAfterConfirmation',
      'downloadPaiementObligeeThFactureFiltredExcel',
      'downloadPaiementObligeeThFactureFiltredPdf',
      'uploadObligeAvoir',
      'validationFactureObligee',
      'fetchTypeListFactureObligee',
      'refreshFactureDossier',
      'exportRapportFactureObligee',
      'downloadMultiAvoirPdfs',
      'getSettingFilialeTh'
    ]),
    returnHistorique(data) {
      this.showPopover = data;
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    },
    remove(item, filter, fct) {
      this[filter].splice(this[filter].indexOf(item), 1);
      this[fct]();
    },
    async openConfirmationGlobalModal() {
      this.$refs['validationGlobalModal'].show();
      this.loader_list_facture = true;
      await this.fetchTypeListFactureObligee();
      this.type = this.getTypeListFactureObligee.map(item => item.value);
      this.loader_list_facture = false;
    },
    async handleOpenAvoirVusialisationModal(item) {
      this.$refs['vusialsationAvoir'].show();
      this.templateAvoir = item.body;
    },
    setVisibilityFieldsHeader(data) {
      // this.computedFields.map(item => {
      this['details_' + data.key] = !this['details_' + data.key];
      // if (item.key == data.key) {
      //   item.details = !item.details;
      // }
      // if (item.for == data.for && item.key != data.key) {
      //   item.views = !item.views;
      // }
      // });
    },
    disabledStartDate(date) {
      return (
        this.date_fin && moment(date, 'YYYY-MM-DD') >= new Date(this.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') < new Date(this.date_debut)
      );
    },
    rowClass(item, type) {
      return 'ligneNormale';
    },
    clickButton(id) {
      this.downloadFactureObligeeItem({
        id: id
      });
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
      this.getAllDataForFilterObligee({
        date_debut: this.date_debut,
        date_fin: this.date_fin
      });
      this.filterLot = [];
      this.filterFiliale = [];
      this.filterOrganisme = [];
      this.handleFilter();
    },
    handleFilter() {
      this.getAppelPaiementObligeeThFiltred({
        lot: this.filterLot,
        organisme: this.filterOrganisme,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        filiale: this.filterFiliale,
        page: this.page,
        per_page: this.per_page,
        search: this.searchValue
      });
      this.setLocalStorageAppeloblige();
    },
    downloadFactureGlobale(item, type) {
      this.downloadFactureGlobaleObligeeItem({ item: item, type: type });
    },
    downloadFactureItem(item, type, id) {
      this.downloadFactureObligeeItem({
        id: id
      });
    },
    async refreshAooelPaiementObligee() {
      let tab = [];
      this.getAppeliPaiementThTableObligee.map(row =>
        row.lots.map(l => {
          if (l.projects.some(p => p.check == true)) {
            tab.push(l.name);
          }
        })
      );
      const response = await this.refreshAooelPaiementObligeeAction({
        lot: tab
      });
      if (response == true) {
        this.handleFilter();
      }
    },
    setLocalStorageAppeloblige() {
      localStorage.setItem(
        'appelOblige',
        JSON.stringify({
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          organisme: this.filterOrganisme,
          lot: this.filterLot,
          filiale: this.filterFiliale,
          search: this.searchValue
        })
      );
    },
    checkAllFunction() {
      this.getAppeliPaiementThTableObligee.map(item => {
        item.check = this.checkAll;
        item.lots.map(itemD => {
          itemD.check = this.checkAll;
          itemD.projects.map(itemP => {
            itemP.check = this.checkAll;
          });
        });
      });
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.lots.map(itemD => {
        itemD.check = item.check;
        itemD.projects.map(itemP => {
          itemP.check = item.check;
        });
      });
    },
    checkAllVerifDetails(data, item) {
      if (item.check == false && data.check == true) {
        data.check = false;
      }
      if (data.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.projects.map(itemP => {
        itemP.check = item.check;
      });
    },
    checkAllVerifDetailsDepot(data, item, itemD, index) {
      if (itemD.check == false && data.check == true) {
        data.check = false;
      }
      if (itemD.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      if (itemD.check == false && item.checkAll == true) {
        item.checkAll = false;
      }
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
      this.checkAll = false;
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
      this.checkAll = false;
    },
    async validationGlobalFactureObligee() {
      this.error = null;
      if (this.type.length == 0) {
        this.error = 'Il faut sélectionner au moins un type';
      } else {
        this.$refs['validationGlobalModal'].hide();
        const response = await this.validationFactureObligee({
          lot: this.filterLot,
          organisme: this.filterOrganisme,
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          filiale: this.filterFiliale,
          dataCheck: this.computedgetLotsToSend,
          type: this.type
        });
        if (response) {
          this.$swal
            .fire({
              icon: 'info',
              confirmButtonText: 'Ok',
              html:
                '<h5>Nombre des factures générées ' +
                this.getResponsevalidationGlobal.success +
                '</h2><br>' +
                '<h5>Nombre des factures déjà existantes ' +
                this.getResponsevalidationGlobal.exist +
                '</h5><br>' +
                '<h5>Nombre des factures erronées ' +
                this.getResponsevalidationGlobal.error +
                '</h5>'
            })
            .then(result => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.handleFilter();
              }
            });
        }
      }
    },
    exportToExcel() {
      this.downloadPaiementObligeeThFiltredExcel({
        lot: this.filterLot,
        organisme: this.filterOrganisme,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        filiale: this.filterFiliale,
        exportExcel: true,
        dataCheck: this.computedgetLotsToSend
      });
    },
    async exportExcelFacture(selectedModel, type) {
      const response = await this.downloadPaiementObligeeThFactureFiltredExcel({
        lot: this.filterLot,
        organisme: this.filterOrganisme,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        filiale: this.filterFiliale,
        modelExport_id: selectedModel,
        famille: 'oblige',
        dataCheck: this.computedgetLotsToSend,
        type: type
      });
      if (response) {
        this.resetModalExportFacture = !this.resetModalExportFacture;
      }
    },
    async exportFacturePdf(type) {
      await this.downloadPaiementObligeeThFactureFiltredPdf({
        dataCheck: this.computedCheckedRows,
        type: type
      });
    },
    handleUploadToExcel() {
      this.$refs['uploadPaiementObligeeTemplateModal'].show();
    },
    resetModal() {
      this.error = null;
      this.type = [];
      this.checkAllType = true;
      this.loadingUpload = false;
      this.errorUpload = null;
      this.file = null;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async change() {
      this.loadingUpload = true;
      if (this.file !== null) {
        const payload = {
          file: this.file

          // depot: this.depot
        };
        const response = await this.uploadObligeexls(payload);
        if (response.succes) {
          this.loadingUpload = false;
          if (response.data && response.data.data) {
            if (response.data.data.length) {
              let extra =
                "<div style='margin: auto 10%;height: 260px;overflow-x: auto'> <h4><i>Dossier(s) existe(nt) :" +
                response.data.data.length +
                ' </i></h4>';

              for (const [key, value] of Object.entries(response.data.data)) {
                extra =
                  extra +
                  '<li style="  font-size: 12px;float: left;width: 100%;"> <b>Dossier ID: </b>' +
                  value.dossier_ID +
                  '<br><b>Numéro dossier: </b>' +
                  value.numero_dossier +
                  '</li>';
              }
              extra = extra + '</div>';
              this.$swal
                .fire({
                  background: 'rgb(245 245 252)',
                  title:
                    'Êtes-vous sur de vouloir écraser les dossiers dupliquer (Le montant min sera recalculé)',
                  type: 'warning',
                  icon: 'warning',
                  locale: 'fr',
                  allowOutsideClick: false,
                  showCloseButton: true,
                  showConfirmButton: true,
                  showCancelButton: true,
                  html: extra,
                  confirmButtonText: 'Oui',
                  cancelButtonText: 'Non',
                  confirmButtonClass: 'btn btn-success',
                  cancelButtonClass: 'btn btn-danger',
                  cancelButtonColor: '#d33',
                  customClass: {
                    actions: 'my-actions',
                    cancelButton: 'order-2 ',
                    confirmButton: 'order-1'
                  }
                })
                .then(async result => {
                  let force = '';
                  if (result.isDismissed && result.dismiss != 'close')
                    force = 'no';
                  if (result.isConfirmed) force = 'oui';
                  if (this.file !== null && force != '') {
                    const payload = {
                      file: this.file,
                      force: force
                      // depot: this.depot
                    };
                    this.loadingUpload = true;
                    const response = await this.uploadObligeexlsAfterConfirmation(
                      payload
                    );
                    this.loadingUpload = false;
                    this.$refs['uploadPaiementObligeeTemplateModal'].hide();
                    this.handleFilter();
                  }
                });
            } else {
              if (this.file !== null) {
                const payload = {
                  file: this.file,
                  force: 'non'
                  // depot: this.depot
                };
                this.loadingUpload = true;
                const response = await this.uploadObligeexlsAfterConfirmation(
                  payload
                );
                this.loadingUpload = false;
                this.$refs['uploadPaiementObligeeTemplateModal'].hide();
                this.handleFilter();
              }
            }
          }
        } else {
          this.loadingUpload = false;
          if (
            this.geterrorObligeXls &&
            (this.geterrorObligeXls.error || this.geterrorObligeXls.missing)
          ) {
            let htmlContent = '';
            let error = '';
            if (this.geterrorObligeXls.error) {
              let error = this.geterrorObligeXls.error;
              htmlContent = error;
            }
            let missing = this.geterrorObligeXls.missing
              ? '<div><h4><i>Colonnes manquantes :</i></h4>'
              : '';
            if (this.geterrorObligeXls.missing) {
              for (const [key, value] of Object.entries(
                this.geterrorObligeXls.missing
              )) {
                missing = missing + '<li>' + value + '</li>';
              }
              missing = missing + '</div>';
              htmlContent =
                "<div style='margin: auto;height: 260px;overflow-x: auto;' class='error-upload-scroll'>" +
                missing +
                '</div>';
            }

            this.$swal.fire({
              background: 'rgb(245 245 252)',
              type: 'error',
              icon: 'error',
              html: htmlContent,
              allowOutsideClick: false,
              showCloseButton: true
            });
          }
        }
      }
    },
    handleFileDrop(e) {
      document.getElementById('uploadInput').value = '';
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach(f => {
        this.file = f;
      });
    },
    handleFileInput(e) {
      let files = e.target.files;
      files = e.target.files;
      if (!files) return;
      [...files].forEach(f => {
        this.file = f;
      });
      document.getElementById('uploadInput').value = '';
    },
    changeAllCheckTypes() {
      this.type =
        this.checkAllType == true
          ? this.getTypeListFactureObligee.map(item => item.value)
          : [];
    }
  },
  computed: {
    ...mapGetters([
      'checkPermission',
      'missingOrganismeInObligeeFile',
      'getChargementAppelPaiementThObligee',
      'getErrorAppeliPaiementThObligee',
      'getErrorAppeliPaiementUpdateThObligee',
      'getAppeliPaiementThTableObligee',
      'getTotalAppelPaiementObligee',
      'getListOrganismeObligee',
      'checkPermission',
      'geTtotalRowsThObligee',
      'getListInstallateurbligee',
      'getListLotsOligee',
      'getLoadingDataFilterObligeeThProjects',
      'getMailLoadingVertigo',
      'geterrorObligeXls',
      'getResponsevalidationGlobal',
      'getLoaderDelteProjectVertigo',
      'getErrorDelteProjectVertigo',
      'getTypeListFactureObligee',
      'getLoadingUploadFileCdg',
      'getShowNotifExportFile'
    ]),
    ComputedFilter() {
      return {
        filterOrganisme: this.filterOrganisme,
        filterFiliale: this.filterFiliale,
        filterLot: this.filterLot,
        num: this.searchValue,
        date_debut: this.date_debut,
        date_fin: this.date_fin
      };
    },
    computedStyle() {
      return this.showPopover == true
        ? 'd-none'
        : ' custem-popover-oblige-facture';
    },
    computedPopover() {
      return this.showPopover == true ? 'click' : 'hover';
    },
    computedCHeckFiliale() {
      return function(data) {
        let check = true;

        if (data) {
          data.lots.forEach(item => {
            if (
              (!item.missing_prime_id && item.missing_prime_ht != '0.00') ||
              (!item.prime_id && item.prime_ht != '0.00') ||
              (((!item.commission_id && item.commission_ht != '0.00') ||
                (!item.surprime_id && item.surprime_ht != '0.00') ||
                (!item.commission_gestion_id &&
                  item.commission_gestion_ht != '0.00')) &&
                (!item.consolidee_energie_invest_id ||
                  item.consolidee_energie_invest_ht == '0.00'))
            ) {
              check = false;
              return;
            }
          });
        }
        return check;
      };
    },
    sizeTable() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 233 + heigthBlock;
      if (this.computedLineFilter) {
        hei = hei + 30;
      }
      if (this.computedTotalProjectSelected > 0) {
        hei = hei + 25;
      }
      if (this.showaTableSum == true && this.computedTotalProjectSelected > 0) {
        hei = hei + 130;
      }

      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    sizeBlockTable() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        if (table.length) {
          for (let i = 0; i < table.length; i++) {
            table2.push(table[i].clientHeight);
          }
        }

        // table.forEach(element => {
        //   table2.push(element.clientHeight);
        // });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 173 + heigthBlock;

      if (this.computedLineFilter) {
        hei = hei + 30;
      }
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    computedCheckChipFilter() {
      if (
        this.filterOrganisme.length > 0 ||
        this.filterFiliale.length > 0 ||
        this.filterLot.length > 0
      ) {
        return true;
      }
      return false;
    },
    computedLineFilter() {
      if (this.date_debut || this.date_fin) {
        return true;
      }
      return false;
    },
    computedResetModalExportFacture() {
      return this.resetModalExportFacture;
    },
    computedgetLotsToSend() {
      let lotsToSend = [];
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          if (item2.check === true) {
            lotsToSend.push(item2.name);
          }
        })
      );
      return lotsToSend;
    },
    computedShowSubRow() {
      return function(data) {
        return this['details_' + data];
        // return this.computedFields.filter(item => item.key == data)[0]?.details;
      };
    },
    computedFields() {
      let fields = [
        {
          key: 'check_all',
          label: '',
          views: true,
          thClass: 'th-check-obligee',
          tdClass: 'col-check-obligee',
          for: 'all',
          show: true
        },
        {
          key: 'details',
          label: '',
          views: true,
          thClass: 'th-plus-obligee',
          tdClass: 'col-plus-obligee',
          for: 'all',
          show: true
        },

        {
          key: 'name',
          label: 'Numéro de lot',
          for: 'all',
          views: true,
          thClass: 'num-lot-th',
          tdClass: 'num-lot-td',
          show: true
        },
        {
          key: 'nom',
          label: 'Nom et prénom',
          for: 'all',
          views: true,
          show: true
        },
        {
          key: 'organisme',
          label: 'Organisme',
          for: 'all',
          views: true,
          show: true
        },
        {
          key: 'reste_a_charge',
          label: 'Reste à charge',
          for: 'all',
          views: true,
          show: true
        },
        {
          key: 'montant_ttc_avoir',
          label: "Montant TTC de l'avoir",
          for: 'all',
          views: true,
          show: true
        },
        {
          key: 'montant_total_ttc',
          label: 'Montant Total TTC',
          for: 'all',
          views: true,
          show: true
        },
        {
          key: 'montant_prime_cee',
          label: 'Montant Prime CEE',
          for: 'all',
          views: true,
          show: true
        },
        {
          key: 'kwh_cumac',
          label: 'Kwh cumac',
          for: 'all',
          views: true,
          show: true
        },

        {
          key: 'm_cumac',
          label: 'Mega cumac',
          for: 'all',
          views: true,
          show: true
        },
        {
          key: 'coef_prime_installateur',
          label: 'Coef prime installateur',
          for: 'all',
          views: true,
          coef: true,
          show: true
        },
        {
          key: 'prime',
          label: 'Prime Installateur',
          for: 'prime',
          views: true,
          details: false,
          thClass: 'prime-bg th-montant-obligee',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACP')
        },
        {
          key: 'prime_pu',
          label: 'Coef prime',
          for: 'prime',
          views: this.details_prime,
          thClass: 'prime-bg',
          show: this.checkPermission('GAPTHACP')
        },
        {
          key: 'prime_ttc',
          label: 'Mt  TTC',
          for: 'prime',
          views: this.details_prime,
          thClass: 'prime-bg',
          show: this.checkPermission('GAPTHACP')
        },
        {
          key: 'prime_tva',
          label: 'Mt  TVA',
          for: 'prime',
          views: this.details_prime,
          thClass: 'prime-bg',
          show: this.checkPermission('GAPTHACP')
        },
        // {
        //   key: 'prime_statut',
        //   label: 'Statut',
        //   for: 'prime',
        //   views: this.details_prime,
        //   thClass: 'prime-bg',
        //   statue: true,
        //   show: this.checkPermission('GAPTHACP'),
        //   editable: this.checkPermission('GAPTHMSPG')
        // },
        {
          key: 'prime_num_facture',
          label: 'Numéro de facture',
          for: 'prime',
          views: this.details_prime,
          thClass: 'prime-bg',
          show: this.checkPermission('GAPTHACP')
        },
        {
          key: 'prime_installateur_avoir',
          label: 'Prime Installateur Avoir',
          for: 'all',
          views: true,
          thClass: 'prime-bg th-montant-obligee',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACAP')
        },
        {
          key: 'missing_prime',
          label: 'Comm installateur',
          for: 'missing_prime',
          views: true,
          details: false,
          thClass: 'missing-bg th-montant-obligee',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACI')
        },
        {
          key: 'missing_prime_pu',
          label: 'Coef Comm',
          for: 'missing_prime',
          views: this.details_missing_prime,
          thClass: 'missing-bg',
          show: this.checkPermission('GAPTHACI')
        },
        {
          key: 'missing_prime_ttc',
          label: 'Mt TTC',
          for: 'missing_prime',
          views: this.details_missing_prime,
          thClass: 'missing-bg',
          show: this.checkPermission('GAPTHACI')
        },

        {
          key: 'missing_prime_tva',
          label: 'Mt TVA',
          for: 'missing_prime',
          views: this.details_missing_prime,
          thClass: 'missing-bg',
          show: this.checkPermission('GAPTHACI')
        },
        // {
        //   key: 'missing_prime_statut',
        //   label: 'Statut',
        //   for: 'missing_prime',
        //   views: this.details_missing_prime,
        //   thClass: 'missing-bg',
        //   statue: true,
        //   show: this.checkPermission('GAPTHACI'),
        //   editable: this.checkPermission('GAPTHMSPIG')
        // },
        {
          key: 'missing_prime_num_facture',
          label: 'Numéro de facture',
          for: 'missing_prime',
          views: this.details_missing_prime,
          thClass: 'missing-bg',
          show: this.checkPermission('GAPTHACI')
        },
        {
          key: 'comm_installateur_avoir',
          label: 'Comm Installateur Avoir',
          for: 'all',
          views: true,
          thClass: 'missing-bg th-montant-obligee',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACAI')
        },

        //commission gestion fields
        {
          key: 'commission_gestion',
          label: 'Commission gestion ht',
          for: 'commition gestion',
          views: true,
          details: false,
          thClass: 'commission-gestion-bg th-montant-obligee',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACCG')
        },
        {
          key: 'commission_gestion_pu',
          label: 'Coef Commission gestion',
          coef: true,
          for: 'commition gestion',
          views: this.details_commission_gestion,
          thClass: 'commission-gestion-bg',
          show: this.checkPermission('GAPTHACCG')
        },
        {
          key: 'commission_gestion_ttc',
          label: 'Mt TTC',
          for: 'commition gestion',
          views: this.details_commission_gestion,
          thClass: 'commission-gestion-bg',
          show: this.checkPermission('GAPTHACCG')
        },

        {
          key: 'commission_gestion_tva',
          label: 'Mt TVA',
          for: 'commition gestion',
          views: this.details_commission_gestion,
          thClass: 'commission-gestion-bg',
          show: this.checkPermission('GAPTHACCG')
        },
        // {
        //   key: 'commission_gestion_statut',
        //   label: 'Statut',
        //   for: 'commition gestion',
        //   views: this.details_commission_gestion,
        //   thClass: 'commission-gestion-bg',
        //   statue: true,
        //   show: this.checkPermission('GAPTHACCG'),
        //   editable: this.checkPermission('GAPTHMGSCG')
        // },

        {
          key: 'commission_gestion_num_facture',
          label: 'Numéro de facture',
          for: 'commission_gestion',
          views: this.details_commission_gestion,
          thClass: 'commission-gestion-bg',
          show: this.checkPermission('GAPTHACCG')
        },
        {
          key: 'commission_gestion_avoir',
          label: 'Commission gestion Avoir',
          for: 'all',
          views: true,
          thClass: 'commission-gestion-bg th-montant-obligee',
          show: this.checkPermission('GAPTHACACG')
        },

        {
          key: 'commission',
          label: 'Commission',
          for: 'commmision',
          views: true,
          details: false,
          thClass: 'commission-bg th-montant-obligee',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACC')
        },
        {
          key: 'commission_pu',
          label: 'Coef commission',
          coef: true,
          for: 'commmision',
          views: this.details_commission,
          thClass: 'commission-bg',
          show: this.checkPermission('GAPTHACC')
        },
        {
          key: 'commission_ttc',
          label: 'MT TTC',
          for: 'commmision',
          views: this.details_commission,
          thClass: 'commission-bg',
          show: this.checkPermission('GAPTHACC')
        },

        {
          key: 'commission_tva',
          label: 'Mt TVA',
          for: 'commmision',
          views: this.details_commission,
          thClass: 'commission-bg',
          show: this.checkPermission('GAPTHACC')
        },
        // {
        //   key: 'commission_statut',
        //   label: 'Statut',
        //   for: 'commmision',
        //   views: this.details_commission,
        //   thClass: 'commission-bg',
        //   statue: true,
        //   show: this.checkPermission('GAPTHACC'),
        //   editable: this.checkPermission('GAPTHMSPCG')
        // },
        {
          key: 'commission_num_facture',
          label: 'Numéro de facture',
          for: 'commmision',
          views: this.details_commission,
          thClass: 'commission-bg',
          show: this.checkPermission('GAPTHACC')
        },
        {
          key: 'commission_avoir',
          label: 'Commission Avoir',
          for: 'all',
          views: true,
          thClass: 'commission-bg th-montant-obligee',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACAC')
        },
        {
          key: 'surprime',
          label: 'Surprime ht',
          for: 'surprime',
          views: true,
          details: false,
          thClass: 'surprime-bg th-montant-obligee',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACS')
        },
        {
          key: 'surprime_pu',
          label: 'Surprime  Coef',
          coef: true,
          for: 'surprime',
          views: this.details_surprime,
          thClass: 'surprime-bg',
          show: this.checkPermission('GAPTHACS')
        },
        {
          key: 'surprime_ttc',
          label: 'Mt TTC',
          for: 'surprime',
          views: this.details_surprime,
          thClass: 'surprime-bg',
          show: this.checkPermission('GAPTHACS')
        },

        {
          key: 'surprime_tva',
          label: 'Mt TVA',
          for: 'surprime',
          views: this.details_surprime,
          thClass: 'surprime-bg',
          show: this.checkPermission('GAPTHACS')
        },
        // {
        //   key: 'surprime_statut',
        //   label: 'Statut',
        //   for: 'surprime',
        //   views: this.details_surprime,
        //   thClass: 'surprime-bg',
        //   statue: true,
        //   show: this.checkPermission('GAPTHACS'),
        //   editable: this.checkPermission('GAPTHMSPSG')
        // },
        {
          key: 'surprime_num_facture',
          label: 'Numéro de facture',
          for: 'surprime',
          views: this.details_surprime,
          thClass: 'surprime-bg',
          show: this.checkPermission('GAPTHACS')
        },
        {
          key: 'surprime_avoir',
          label: 'Surprime Avoir',
          for: 'all',
          views: true,
          thClass: 'surprime-bg th-montant-obligee',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACAS')
        },
        //Facture consolidée (Installateur)
        {
          key: 'consolidee_energie_invest',
          label: 'Facture consolidée (Energie invest)',
          for: 'Facture consolidée',
          views: true,
          details: false,
          thClass: 'consolidee-energie-invest-bg th-montant-obligee',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACFCEI')
        },
        {
          key: 'consolidee_energie_invest_pu',
          label: 'Coef Facture consolidée (Installateur)',
          coef: true,
          for: 'Facture consolide',
          views: this.details_consolidee_energie_invest,
          thClass: 'consolidee-energie-invest-bg',
          show: this.checkPermission('GAPTHACFCEI')
        },
        {
          key: 'consolidee_energie_invest_ttc',
          label: 'Mt TTC',
          for: 'Facture consolide',
          views: this.details_consolidee_energie_invest,
          thClass: 'consolidee-energie-invest-bg',
          show: this.checkPermission('GAPTHACFCEI')
        },

        {
          key: 'consolidee_energie_invest_tva',
          label: 'Mt TVA',
          for: 'Facture consolide',
          views: this.details_consolidee_energie_invest,
          thClass: 'consolidee-energie-invest-bg',
          show: this.checkPermission('GAPTHACFCEI')
        },
        // {
        //   key: 'commission_gestion_statut',
        //   label: 'Statut',
        //   for: 'commition gestion',
        //   views: this.details_commission_gestion,
        //   thClass: 'consolidee-energie-invest-bg',
        //   statue: true,
        //   show: this.checkPermission('GAPTHACCG'),
        //   editable: this.checkPermission('GAPTHMGSCG')
        // },

        {
          key: 'consolidee_energie_invest_num_facture',
          label: 'Numéro de facture',
          for: 'Facture consolide',
          views: this.details_consolidee_energie_invest,
          thClass: 'consolidee-energie-invest-bg',
          show: this.checkPermission('GAPTHACFCEI')
        },
        {
          key: 'consolidee_energie_invest_avoir',
          label: 'Facture consolide Avoir',
          for: 'all',
          views: true,
          thClass: 'consolidee-energie-invest-bg th-montant-obligee',
          show: this.checkPermission('GAPTHACFCEI')
        },
        {
          key: 'total',
          label: 'Total Coef',
          for: 'all',
          views: true,
          thClass: 'th-total-coef-oblige',
          tdClass: 'td-total-coef-oblige',
          show: true
        }
      ];
      return fields;
    },
    computedFields2() {
      return this.computedFields.filter(
        item => item.show == true && item.views == true
      );
    },
    computedHeaderVisibility() {
      return function(data) {
        if (
          data == 'prime' ||
          data == 'surprime' ||
          data == 'missing_prime' ||
          data == 'commission_gestion' ||
          data == 'commission' ||
          data == 'consolidee_energie_invest'
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    computedCantUpdate() {
      return false;
    },
    computedTotalDepot() {
      return function(data) {
        if (data) {
          let x =
            parseFloat(data.commission_pu.replace(' ', '')) +
            parseFloat(data.prime_pu.replace(' ', '')) +
            parseFloat(data.surprime_pu.replace(' ', '')) +
            parseFloat(data.missing_prime_pu.replace(' ', ''));
          let res = x.toFixed(2);
          return res;
        }
        return '00.00';
      };
    },
    computedTotal() {
      return function(data) {
        if (data) {
          let x =
            parseFloat(data.commission.replace(' ', '')) +
            parseFloat(data.prime.replace(' ', '')) +
            parseFloat(data.surprime.replace(' ', '')) +
            parseFloat(data.missing_prime.replace(' ', ''));
          let res = x.toFixed(2);
          return res;
        }
        return '00.00';
      };
    },
    computedGetAppeliPaiementThTableObligeeCategories() {
      let table = [];
      if (
        this.getAppeliPaiementThTableObligee &&
        this.getAppeliPaiementThTableObligee.length
      ) {
        this.getAppeliPaiementThTableObligee.map(item => {
          table.push(item.name);
        });
      }

      return table;
    },
    computedGetAppeliPaiementThTableObligeeTotal_pu_commission() {
      let table = [];
      if (
        this.getAppeliPaiementThTableObligee &&
        this.getAppeliPaiementThTableObligee.length
      ) {
        this.getAppeliPaiementThTableObligee.map(item => {
          table.push(parseFloat(item.commission_pu));
        });
      }

      return table;
    },
    computedGetAppeliPaiementThTableObligeeTotal_pu_prime() {
      let table = [];
      if (
        this.getAppeliPaiementThTableObligee &&
        this.getAppeliPaiementThTableObligee.length
      ) {
        this.getAppeliPaiementThTableObligee.map(item => {
          table.push(parseFloat(item.prime_pu));
        });
      }

      return table;
    },
    computedGetAppeliPaiementThTableObligeeTotal_pu_missing_prime() {
      let table = [];
      if (
        this.getAppeliPaiementThTableObligee &&
        this.getAppeliPaiementThTableObligee.length
      ) {
        this.getAppeliPaiementThTableObligee.map(item => {
          table.push(parseFloat(item.missing_prime_pu));
        });
      }

      return table;
    },

    computedGetAppeliPaiementThTableObligeeTotal_pu_surprime() {
      let table = [];
      if (
        this.getAppeliPaiementThTableObligee &&
        this.getAppeliPaiementThTableObligee.length
      ) {
        this.getAppeliPaiementThTableObligee.map(item => {
          table.push(parseFloat(item.surprime_pu));
        });
      }

      return table;
    },
    chartOptions() {
      return {
        chart: {
          height: '100%',
          type: 'line',
          stacked: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [1, 1, 4]
        },
        title: {
          text: '',
          align: 'left',
          offsetX: 170
        },
        xaxis: {
          categories: this.computedGetAppeliPaiementThTableObligeeCategories
        },
        yaxis: [
          {
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: '#008FFB'
            },
            labels: {
              style: {
                colors: '#2a2a2a'
              }
            },
            // title: {
            //   text: 'Income (thousand crores)',
            //   style: {
            //     color: '#008FFB'
            //   }
            // },
            tooltip: {
              enabled: true
            }
          }
          // {
          //   seriesName: 'Income',
          //   opposite: true,
          //   axisTicks: {
          //     show: true
          //   },
          //   axisBorder: {
          //     show: true,
          //     color: '#00E396'
          //   },
          //   labels: {
          //     style: {
          //       colors: '#00E396'
          //     }
          //   },
          //   title: {
          //     text: 'Operating Cashflow (thousand crores)',
          //     style: {
          //       color: '#00E396'
          //     }
          //   }
          // }
          // {
          //   seriesName: 'Revenue',
          //   opposite: true,
          //   axisTicks: {
          //     show: true
          //   },
          //   axisBorder: {
          //     show: true,
          //     color: '#FEB019'
          //   },
          //   labels: {
          //     style: {
          //       colors: '#FEB019'
          //     }
          //   },
          //   title: {
          //     text: 'Revenue (thousand crores)',
          //     style: {
          //       color: '#FEB019'
          //     }
          //   }
          // }
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60
          }
        }
      };
    },
    series() {
      return [
        {
          name: 'Montant commission',
          type: 'column',
          data: this.computedGetAppeliPaiementThTableObligeeTotal_pu_commission,
          color: '#FF4560'
        },
        {
          name: 'Montant prime',
          type: 'column',
          data: this.computedGetAppeliPaiementThTableObligeeTotal_pu_prime,
          color: '#4d4bac'
        },
        {
          name: 'Montant surprime',
          type: 'column',
          data: this.computedGetAppeliPaiementThTableObligeeTotal_pu_surprime
        },
        {
          name: 'Montant extrat',
          type: 'column',
          data: this
            .computedGetAppeliPaiementThTableObligeeTotal_pu_missing_prime,
          color: '#1ae9f3'
        }
      ];
    },
    computedDownloadGlobal() {
      return function(data) {
        for (let i = 0; i < data.item.length; i++) {
          if (data.item[i][data.column] == null) {
            return false;
          }
        }
        return true;
      };
    },
    computedCheckedRows() {
      let result1 = this.getAppeliPaiementThTableObligee.filter(row => {
        return row.lots.some(l => l.projects.some(p => p.check == true));
      });
      return result1;
    },
    computedCheckedRowsWithFact() {
      let result1 = this.getAppeliPaiementThTableObligee.filter(row => {
        return row.lots.some(
          l =>
            l.projects.some(p => p.check == true) &&
            l.missing_prime_factures.length == 0 &&
            l.prime_factures.length == 0 &&
            l.surprime_factures.length == 0 &&
            l.commission_factures.length == 0 &&
            l.commission_gestion_factures.length == 0 &&
            l.consolidee_energie_invest_factures.length == 0
        );
      });
      return result1;
    },
    computedCheckedRowsLots() {
      let result1 = this.getAppeliPaiementThTableObligee.filter(row => {
        return row.lots.some(l => l.check == true);
      });
      return result1;
    },
    computedAcheuteurToSend() {
      let listClient = [];
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            if (listClient.indexOf(item2.name_acheteur) === -1) {
              listClient.push(item2.name_acheteur);
            }
          }
        })
      );
      return listClient;
    },
    computedTotalProjectSelected() {
      let total = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                total++;
              }
            });
          }
        })
      );
      return total;
    },
    computedRestAcharge() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.reste_a_charge) * 100) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantTtcAvoir() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.montant_ttc_avoir) * 100) / 100 ||
                  0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantTotalTtc() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.montant_total_ttc) * 100) / 100 ||
                  0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantPrimeCee() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.montant_prime_cee) * 100) / 100 ||
                  0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantkwh() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.kwh_cumac) * 100) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantMegaCumac() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.m_cumac.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantPrimeInstallateur() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.prime_ht.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantPrimeInstallateurTTC() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.prime_ttc.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantPrimeInstallateurTVA() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.prime_tva.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommInstallateur() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (
                item3.check === true &&
                parseFloat(
                  item3.missing_prime_ht.replace(' ', '').replace(',', '.')
                ) > 0
              ) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.missing_prime_ht.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommInstallateurTTC() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (
                item3.check === true &&
                parseFloat(
                  item3.missing_prime_ttc.replace(' ', '').replace(',', '.')
                ) > 0
              ) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.missing_prime_ttc.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommInstallateurTVA() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (
                item3.check === true &&
                parseFloat(
                  item3.missing_prime_tva.replace(' ', '').replace(',', '.')
                ) > 0
              ) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.missing_prime_tva.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommission() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.commission_ht.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommissionTTC() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.commission_ttc.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommissionTVA() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.commission_tva) * 100) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantSurprime() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.surprime_ht.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantSurprimeTTC() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.surprime_ttc.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantSurprimeTVA() {
      let somme = 0;
      this.computedCheckedRows.map(item1 => {
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.surprime_tva.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        });
      });
      return somme;
    },
    computedMontantConsolideeEnergieInvest() {
      let somme = 0;
      this.computedCheckedRows.map(item1 => {
        item1?.lots?.map(item2 => {
          {
            // item2?.projects?.map(item3 => {
            if (
              item2.check === true &&
              parseFloat(
                item2?.consolidee_energie_invest_ht
                  ?.replace(' ', '')
                  .replace(',', '.')
              ) > 0
            ) {
              somme +=
                Math.round(
                  parseFloat(
                    item2?.consolidee_energie_invest_ht
                      ?.replace(' ', '')
                      .replace(',', '.')
                  ) * 100
                ) / 100 || 0;
            }
            // });
          }
        });
      });
      return somme;
      return 0;
    },

    computedMontantConsolideeEnergieInvestTVA() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1?.lots?.map(item2 => {
          {
            // item2?.projects?.map(item3 => {
            if (
              item2?.check === true &&
              parseFloat(
                item2?.consolidee_energie_invest_tva
                  ?.replace(' ', '')
                  .replace(',', '.')
              ) > 0
            ) {
              somme +=
                Math.round(
                  parseFloat(
                    item2?.consolidee_energie_invest_tva
                      ?.replace(' ', '')
                      .replace(',', '.')
                  ) * 100
                ) / 100 || 0;
            }
            // });
          }
        })
      );
      return somme;
    },
    computedMontantConsolideeEnergieInvestTTC() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1?.lots?.map(item2 => {
          {
            // item2?.projects?.map(item3 => {
            if (
              item2?.check === true &&
              parseFloat(
                item2?.consolidee_energie_invest_ttc
                  ?.replace(' ', '')
                  .replace(',', '.')
              ) > 0
            ) {
              somme +=
                Math.round(
                  parseFloat(
                    item2?.consolidee_energie_invest_ttc
                      ?.replace(' ', '')
                      .replace(',', '.')
                  ) * 100
                ) / 100 || 0;
            }
            // });
          }
        })
      );
      return somme;
    },
    computedMontantConsolideeEnergieInvestAvoir() {
      let somme = 0;
      this.computedCheckedRows.map(item1 => {
        item1?.lots?.map(item2 => {
          {
            if (item2.check === true) {
              somme +=
                Math.round(
                  parseFloat(item2.consolidee_energie_invest_avoir) * 100
                ) / 100 || 0;
            }
          }
        });
      });
      return somme;
    },
    //avoir
    computedMontantPrimeAvoir() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            if (item2.check === true) {
              somme +=
                Math.round(parseFloat(item2.prime_avoir) * 100) / 100 || 0;
            }
          }
        })
      );
      return somme;
    },
    computedMontantMissingPrimeAvoir() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            if (item2.check === true) {
              somme +=
                Math.round(parseFloat(item2.missing_prime_avoir) * 100) / 100 ||
                0;
            }
          }
        })
      );
      return somme;
    },
    computedMontantCommissionAvoir() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            if (item2.check === true) {
              somme +=
                Math.round(parseFloat(item2.commission_avoir) * 100) / 100 || 0;
            }
          }
        })
      );
      return somme;
    },
    computedMontantSurprimeAvoir() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            if (item2.check === true) {
              somme +=
                Math.round(parseFloat(item2.surprime_avoir) * 100) / 100 || 0;
            }
          }
        })
      );
      return somme;
    },
    computedMontantCommissionGestion() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.commission_gestion_ht
                        .replace(' ', '')
                        .replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommissionGestionTTC() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.commission_gestion_ttc
                        .replace(' ', '')
                        .replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommissionGestionTVA() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.commission_gestion_tva) * 100) /
                    100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommissionGestionAvoir() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            if (item2.check === true) {
              somme +=
                Math.round(parseFloat(item2.commission_gestion_avoir) * 100) /
                  100 || 0;
            }
          }
        })
      );
      return somme;
    }
  },

  components: {
    Historique: () => import('@/views/component/Historique.vue'),
    search: () => import('@/views/component/SearchInputComponent.vue'),
    GenerateFactureMaster: () =>
      import('./component/GenerateFactureMaster.vue'),
    // apexchart: VueApexCharts,
    EditableInputSuiviTh: () =>
      import('@/views/component/EditableInputSuiviTh'),
    // EditableInput: () => import('../../component/EditableInput.vue'),
    TemplateObligee,
    exportRapportOblige,
    columnHeaderWithToolTip: () =>
      import('./component/columnHeaderWithToolTip.vue'),
    ValidationStatueObligee: () =>
      import('./component/ValidationStatueObligee.vue'),
    MailComponent: () => import('./component/MailComponent.vue'),
    ExportExcelFacture: () => import('./component/exportExcelFacture'),
    ExportExcelFacturePdf: () => import('./component/exportExcelFacturePdf'),
    deleteProject: () => import('./component/deleteProject.vue'),
    UploadFiles: () => import('@/views/component/UploadFiles.vue'),
    loader: () => import('@/views/Loader.vue'),
    vueObligeFacture: () =>
      import('./component/vue2Obligee/vueObligeFacture.vue')
  },
  async mounted() {
    this.date_debut = moment()
      .clone()
      .startOf('year')
      .format('YYYY-MM-DD');
    this.getAllDataForFilterObligee({
      date_debut: this.date_debut,
      date_fin: this.date_fin
    });

    if (localStorage.getItem('appelOblige')) {
      this.date_debut = JSON.parse(
        localStorage.getItem('appelOblige')
      ).date_debut;
      this.date_fin = JSON.parse(localStorage.getItem('appelOblige')).date_fin;
      this.filterLot = JSON.parse(localStorage.getItem('appelOblige')).lot;
      this.filterOrganisme = JSON.parse(
        localStorage.getItem('appelOblige')
      ).organisme;
      this.filterFiliale = JSON.parse(
        localStorage.getItem('appelOblige')
      ).filiale;
      this.searchValue = JSON.parse(
        localStorage.getItem('appelOblige')
      )?.search;
    } else {
      this.setLocalStorageAppeloblige();
    }
    this.fetchTypeListFactureObligee();
    this.getSettingFilialeTh({ type: 'master filiale' });

    this.handleFilter();
  }
};
</script>

<style scoped lang="scss">
.gestion-appel-paiement-obligee {
  margin: 0px;
  width: 100%;
  padding: 10px;
  display: block;
  height: calc(100vh - 60px);
  overflow: hidden;
  .entete {
    width: 100%;
    .chargement {
      font-size: 8px;
      margin-left: 5px;
      .spinner-border {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .body-box-rapport {
    height: calc(100vh - 175px);
  }
}
.icon-plus {
  font-size: 13px;
  color: #8d8cb7;
  &:hover {
    color: #4d4bac;
  }
}
.border-botom {
  border-bottom: 1px solid #f6f5fb;
}
.table-appel-paiement-obligee-th {
  max-height: calc(100vh - 230px) !important;
  height: calc(100vh - 230px) !important;
  margin-bottom: 0px;
  overflow-x: scroll;
}
.table-appel-paiement-obligee-th-with-summ {
  max-height: calc(100vh - 326px) !important;
  height: calc(100vh - 326px) !important;
  margin-bottom: 0px;
  overflow-x: scroll;
}
.background-td-prim {
  background-color: #fef2cb;
}
.background-td-comm {
  background-color: #dadada;
}
.background-td-commission {
  background-color: #fbe4d5;
}
.background-td-suprim {
  background-color: #b4c6e7;
}
.consolidee-td-energie-invest {
  background-color: #e88793 !important;
}
.sticky-column-body {
  position: absolute;
  background-color: #f6f5fb;
  margin-left: 0px;
}
.sticky-column-head {
  position: absolute;
  background-color: #f0f1ff;
  margin-left: 0px;
}
.tooltip-position {
  display: flex;
}
.content-tooltip-style {
  height: 30px;
}
.text-avoir-color {
  color: red;
}
.margin-text-tooltip {
  margin-top: 10px;
}
.title-tabel-obligie {
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  color: #ffffff;
  white-space: nowrap;
}
</style>
<style lang="scss">
.test {
  display: none !important;
  // color : red
}
.gestion-appel-paiement-obligee {
  .table-rapport-style {
    display: block;
    #chart {
      position: relative;
      // max-width: 40%;
      // width: 38%;
      width: 100%;
    }
  }
  .justify-content-sb {
    // display: flex !important;
    line-height: 11px !important;
    padding: 0px 8px !important;
    justify-content: space-between !important;
  }
  .button-export-style {
    padding-left: 1.5px;
    padding-right: 1.7px;
    img {
      width: 24px;
    }
  }
}
.table-appel-paiement-obligee-th-with-summ {
  td {
    width: 100px;
    min-width: 100px;
    line-height: 10px;
    span {
      cursor: pointer;
      display: table-row;
      vertical-align: inherit;
    }
  }
  td,
  th {
    font-size: 9px;
  }
  th {
    padding: 6px 2px;
    width: 100px;
    min-width: 100px;
  }
  .background-week {
    // justify-content: start !important;
    background-color: #e0e0eb;
    color: #fff;
    vertical-align: middle;
    td {
      width: 100px;
      min-width: 100px;
      padding: 0px;
      margin: 0px;
      font-size: 9.5px;
      // border-right: 1px solid white;
    }
    .button-succes-style {
      background-color: #6d6c99;
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .icon-info-show {
      color: #e0e0eb !important;
    }
    .col-plus-obligee,
    .num-lot-td,
    .col-check-obligee,
    .td-total-coef-oblige {
      background-color: #e0e0eb !important;
      z-index: 6;
    }
    .num-lot-td {
      display: grid;
      grid-template-columns: 20% 80%;
    }
    .item-details-style {
      border: 1px solid #fff;
      display: table-cell;
    }
  }
  .align-td-item {
    padding: 0px !important;
    .item-details-style {
      justify-content: center;
    }
  }
  .background-depot {
    justify-content: start !important;
    background-color: #b0afd9;
    padding: 4px 0px;
    display: table-row;
    vertical-align: middle;

    td {
      width: 100px;
      min-width: 100px;
      padding: 4px;
      margin: 0px;
      font-size: 9.5px;
    }
    .button-succes-style {
      background-color: #fff;
      color: #4d4bac;
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .icon-info-show {
      color: #b0afd9 !important;
    }
    .col-plus-obligee,
    .col-check-obligee,
    .num-lot-td,
    .td-total-coef-oblige {
      background-color: #b0afd9 !important;
      min-height: 19px;
    }
    .item-details-style {
      align-self: center;
      color: #fff;
      display: table-cell;
      border: 1px solid #fff;

      margin: 0;
    }
  }
  .center-th-montant {
    display: flex !important;
    position: relative;
  }
}
.table-appel-paiement-obligee-th {
  td {
    width: 100px;
    min-width: 100px;
    line-height: 10px;
    span {
      cursor: pointer;
      display: table-row;
      vertical-align: inherit;
    }
  }
  td,
  th {
    font-size: 9px;
  }
  th {
    padding: 6px 2px;
    width: 100px;
    min-width: 100px;
  }
  .background-week {
    justify-content: start !important;
    background-color: #e0e0eb;
    color: #fff;
    vertical-align: middle;
    td {
      width: 100px;
      min-width: 100px;
      padding: 0px;
      margin: 0px;
      font-size: 9.5px;
      // border-right: 1px solid white;
    }
    .button-succes-style {
      background-color: #6d6c99;
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .icon-info-show {
      color: #e0e0eb !important;
    }
    .col-plus-obligee,
    .num-lot-td,
    .col-check-obligee,
    .td-total-coef-oblige {
      background-color: #e0e0eb !important;
      z-index: 6;
    }
    .num-lot-td {
      display: grid;
      grid-template-columns: 20% 80%;
    }
    .item-details-style {
      border: 1px solid #fff;
      display: table-cell;
    }
  }
  .align-td-item {
    padding: 0px !important;
    .item-details-style {
      justify-content: center;
    }
  }
  .background-depot {
    justify-content: start !important;
    background-color: #b0afd9;
    padding: 4px 0px;
    display: table-row;
    vertical-align: middle;

    td {
      width: 100px;
      min-width: 100px;
      padding: 4px;
      margin: 0px;
      font-size: 9.5px;
    }
    .button-succes-style {
      background-color: #fff;
      color: #4d4bac;
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .icon-info-show {
      color: #b0afd9 !important;
    }
    .col-plus-obligee,
    .col-check-obligee,
    .num-lot-td,
    .td-total-coef-oblige {
      background-color: #b0afd9 !important;
      min-height: 19px;
    }
    .item-details-style {
      align-self: center;
      color: #fff;
      display: table-cell;
      border: 1px solid #fff;

      margin: 0;
    }
  }
  .center-th-montant {
    display: flex !important;
    position: relative;
  }
}

.info-icon-commercial-lot {
  font-size: 11.5px;
  margin-right: 4px;
  color: #e09f24;
  &:hover {
    color: #f5a206;
  }
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #7978aa;
  border-radius: 7px;
}
.time-icon-style {
  margin-top: 33px;
  color: red;
}

// .icon-plus-double {
//   font-size: 13px;
//   margin-right: 4px;
//   color: #e09f24;
//   &:hover {
//     color: #f5a206;
//   }
// }
.loader-mail-size {
  width: 24px;
  height: 24px;
}
</style>
<style lang="scss">
.customSelectMultipleFilterLot {
  width: 160px !important;
}

.error_oblige_update {
  position: fixed;
  bottom: 45px;
  right: 45px;
  z-index: 99;
  font-size: 15px;
  width: 350px;
  .alert-danger {
    padding: 20px;
  }
}
.table-appel-paiement-obligee-th {
  .oblige-validation-frais {
    position: absolute;
    right: 0;
  }

  td,
  th {
    border-left: 1px solid white;
    border-right: 0px solid !important;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
}
.table-appel-paiement-obligee-th-with-summ {
  .oblige-validation-frais {
    position: absolute;
    right: 0;
  }

  td,
  th {
    border-left: 1px solid white;
    border-right: 0px solid !important;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
}
.AppeluploadFile {
  position: relative;
  z-index: 3;
  width: 100%;
  text-align: center;
  border-radius: 19px;
  height: calc(100% - 74px);
  justify-content: center;
  align-items: center;
  display: flex;
  .footer-upload {
    width: 100%;
    bottom: 0px;
    position: absolute;
    z-index: 0;
  }
  .box-white {
    z-index: 50;
    width: fit-content;
    padding: 20px 20px 25px 0px;
    background: #f5f4fa;
    border-radius: 17px;
    width: 90%;
    top: -5%;
    position: relative;
    text-align: initial;
    .box-title-uplaod {
      position: relative;
      background-color: #ffffff;
      width: 215px;
      padding: 7px 23px;
      border-radius: 0px 18px 18px 0px;
      text-align: end;
      margin-bottom: 26px;
      .text {
        font-size: 15px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        color: #5d5d5d;
      }
    }
  }
  .container {
    height: 187px;
    width: 380px;
    .file-wrapper {
      text-align: center;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      position: relative;
      overflow: hidden;
      border: 1px dashed #4d4bac;
      background-color: #ffffff;
      border-radius: 5px;
      box-shadow: 1px 2px 5px 5px #f5f5f5;
      input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 5;
      }
      .display {
        display: none;
      }
      .upload_label {
        font-size: 40px;
        position: relative;
        top: 32px;
        .drag-title {
          font-size: 15px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          color: #4d4bac;
        }
        p {
          font-size: 15px;
        }
        .icon-upload {
          width: 33px;
          height: 33px;
          margin-bottom: 15px;
        }
        .btn-upload-file {
          font-family: 'Montserrat', sans-serif;
          font-size: 14px;
          font-weight: 400;
          width: 60%;
          border-radius: 29px;
          background-color: #4d4bac;
          padding: 11px 20px;
          border: 0;
          color: #fff;
          margin: auto;
        }
      }
    }
  }
  .file-exel-style {
    font-size: 8px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #5d5d5d;
    position: relative;
    right: 23%;
    text-align: end;
    margin-top: 10px;
    span {
      font-weight: 500;
      color: #fe808b;
    }
  }
  .error-upload {
    position: absolute;
    width: 100%;
    font-size: 12px;
    text-align: center;
    bottom: -2%;
    right: 3%;
    z-index: 50;
    color: red;
    .title-error {
      font-weight: 600;
    }
  }
}
.custom-modal-size-upload .modal-dialog {
  max-width: 47%;
  top: 0% !important;
  height: 100%;
  margin: auto !important;
}

.error-upload-scroll::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.error-upload-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
.error-upload-scroll::-webkit-scrollbar-thumb {
  background: #b5b5e6;
  border-radius: 7px;
}
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}

.text-missing-organisme-obligee {
  font-size: 10px;
  font-weight: 700;
}
.avoir-tooltip {
  .tooltip-inner {
    max-width: 500px !important;
    width: 300px !important;
  }
}

.loader-obligee-delete-projet {
  position: absolute;
  /* top: 50%; */
  z-index: 100;
  background: #ffffffa1;
  /* left: 50%; */
  padding-left: 50%;
  padding-top: 14%;
  width: 100%;
  height: 100%;
}
.custem-popover-oblige-facture {
  width: max-content;
  max-width: max-content;
}
</style>
