<template>
  <div
    class="oblige-validation-frais "
    :class="{ 'mt-2': Array.isArray(this.download) }"
  >
    <div v-if="visialisation">
      <font-awesome-icon
        icon="eye"
        class="icon-style-color mr-1"
        @click="handleOpenAvoirVusialisationModal()"
      />
    </div>
    <div class="mr-1" v-if="showDownloadButton && download">
      <font-awesome-icon
        icon="arrow-alt-circle-down"
        class="icon-plus"
        @click.prevent="downloadFactureItem()"
      />
    </div>
    <div v-if="show_generate_facture_file_code && value != 0.0">
      <font-awesome-icon
        icon="file-code"
        @click.prevent="handleEditTemplate"
        class="icon-style-color mr-1"
        :class="IconClass"
      />
    </div>

    <div
      v-if="
        download &&
          Array.isArray(download) &&
          download.length > 0 &&
          type2 !== 'avoir' &&
          showDownloadButton
      "
    >
      <font-awesome-icon icon="check-circle" class="icon-style-check-facture" />
    </div>
    <!-- facture to upload  template -->
    <b-modal
      no-close-on-backdrop
      ref="uploadFactureTemplateModal"
      id="uploadFactureTemplateModal"
      :title="
        getTemplateObligeeToUpload && getTemplateObligeeToUpload.name
          ? getTemplateObligeeToUpload.name
          : ''
      "
      size="lg"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal('uploadFactureTemplateModal')"
      modal-class="cutsom-modal-bootstrap custom-modal-template-frais-cdg"
    >
      <div
        class="iconClose"
        @click.prevent="hideModal('uploadFactureTemplateModal')"
      >
        <font-awesome-icon icon="times" class="icon-time-position" />
      </div>
      <div class="message text-aligne-center">
        <div
          v-if="getChargementAppelPaiementThObligeeFacture == true"
          class="loading-custom-template-block"
        >
          <Half-circle-spinner
            :animation-duration="1000"
            :size="50"
            :color="'#4d4bac'"
            class="loeder"
          />
        </div>
        <div
          v-if="getErrorAppeliPaiementThObligeeFacture"
          class="error-message"
        >
          <div class="error">
            {{ getErrorAppeliPaiementThObligeeFacture }}
          </div>
        </div>
      </div>
      <form @submit.prevent.stop="handleUpload">
        <div
          class="oblige-template-block-style"
          v-if="getTemplateObligeeToUpload"
          id="hmt_block_id"
        >
          <b-tabs content-class="mt-3" class="tabs-class">
            <b-tab @click="clickFormTabs" title="Formulaire">
              <b-row class="input-fields-style-for-template">
                <b-col class="col-4">
                  <div class="form-actions mt-3 mb-5 text-aligne-center">
                    <b-button
                      class="button-succes-style ml-2"
                      size="sm"
                      variant="success"
                      type="submit"
                      :disabled="computedDisabledValidateButton.length === 0"
                    >
                      Valider

                      <font-awesome-icon
                        icon="arrow-alt-circle-right"
                        class="ml-2"
                      />
                    </b-button>
                    <b-button
                      v-if="
                        can_reload === true && type2 === 'non-avoir' && download
                      "
                      size="sm"
                      class="refrech-button-style ml-5"
                      title="Actualiser la facture"
                      @click="handleRefreche"
                    >
                      <font-awesome-icon icon="retweet"
                    /></b-button>
                    <div class="message text-aligne-center">
                      <div v-if="errorUpload" class="error-message">
                        <div class="error">{{ errorUpload }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="fields-input">
                    <b-form-group
                      label="Date de Facture"
                      class="input-modal-champ "
                    >
                      <date-picker
                        value-type="format"
                        format="DD-MM-YYYY"
                        type="date"
                        :value="getTemplateObligeeToUpload.date"
                        class="inputDateTemplate"
                        @input="
                          changeFieldsValue({
                            key: 'date_id',
                            value: getTemplateObligeeToUpload.date,
                            e: $event,
                            champName: 'date'
                          })
                        "
                        @focus="
                          focusInFields({
                            key: 'date_id'
                          })
                        "
                      ></date-picker>
                    </b-form-group>
                    <!-- payment condition  -->
                    <b-form-group
                      label="Conditions du paiement"
                      label-for="payement-condition"
                    >
                      <multiselect
                        v-model="getTemplateObligeeToUpload.payment_condition"
                        :options="computedConditionPaiement"
                        :multiple="false"
                        label="name"
                        track-by="id"
                        required
                        class="multiselect-vue-custom-style-cdg"
                        :showLabels="false"
                      >
                      </multiselect>
                    </b-form-group>
                    <b-form-group
                      label="HT"
                      label-for="title-template-ht"
                      class="input-modal-champ "
                    >
                      <b-form-input
                        autocomplete="off"
                        id="title-template-ht"
                        v-model="computedTotalHt"
                        @input="
                          changeFieldsValue({
                            key: 'ht_id',
                            value: getTemplateObligeeToUpload.ht
                          })
                        "
                        @focus="
                          focusInFields({
                            key: 'ht_id'
                          })
                        "
                        disabled
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="TVA "
                      label-for="title-template-num-fact"
                      class="input-modal-champ "
                    >
                      <b-form-input
                        id="title-template-num-fact"
                        v-model="computedTotalTva"
                        required
                        @input="
                          changeFieldsValue({
                            key: 'tva_id',
                            value: getTemplateObligeeToUpload.tva
                          })
                        "
                        @focus="
                          focusInFields({
                            key: 'tva_id'
                          })
                        "
                        disabled
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="TTC"
                      label-for="title-template-banque"
                      class="input-modal-champ"
                    >
                      <b-form-input
                        autocomplete="off"
                        id="title-template-banque"
                        v-model="computedTotalTTC"
                        @input="
                          changeFieldsValue({
                            key: 'ttc_id',
                            value: getTemplateObligeeToUpload.ttc
                          })
                        "
                        @focus="
                          focusInFields({
                            key: 'ttc_id'
                          })
                        "
                        disabled
                      ></b-form-input>
                    </b-form-group>

                    <!-- Rib -->
                    <div>
                      <!-- Rib  -->
                      <b-form-group
                        label="Rib"
                        label-for="rib-template-select"
                        v-if="getTemplateObligeeToUpload"
                      >
                        <multiselect
                          v-model="getTemplateObligeeToUpload.rib"
                          :options="computedRibs"
                          :multiple="false"
                          required
                          class=" multiselect-vue-custom-style-cdg"
                          :showLabels="false"
                        >
                        </multiselect>
                      </b-form-group>
                    </div>
                  </div>
                </b-col>
                <b-col class="col-8">
                  <b-row v-if="type !== 'consolidee_energie_invest'">
                    <b-col class="col-2"> <b>Désignation</b></b-col>
                    <b-col class="col-1"><b>Réf</b></b-col>
                    <b-col class="col-2"><b>Qte</b></b-col>
                    <b-col class="col-1 pl-0 pr-0 mr-0 ml-1"
                      ><b> PU HT </b></b-col
                    >
                    <b-col class="col-2"><b> Total HT </b></b-col>
                    <b-col class="col-1"><b> TVA%</b></b-col>
                    <b-col class="col-2"><b> Total TTC </b></b-col>
                  </b-row>
                  <b-row
                    v-for="(product,
                    index) in getTemplateObligeeToUpload.products"
                    :key="index"
                    class="mt-2"
                  >
                    <b-row
                      v-if="
                        computedIndexToSeparate.includes(index) &&
                          type === 'consolidee_energie_invest'
                      "
                      class="separate-type d-flex justify-content-center col-12 mb-1"
                    >
                      <span
                        ><b
                          >--
                          {{
                            computedFormattedTypeObligee(product.type_obligee)
                          }}

                          --</b
                        ></span
                      >
                    </b-row>
                    <b-row
                      v-if="
                        computedIndexToSeparate.includes(index) &&
                          type === 'consolidee_energie_invest'
                      "
                      class="col-12"
                    >
                      <b-col class="col-2 pl-1"> <b>Désignation</b></b-col>
                      <b-col class="col-1 pl-1"><b>Réf</b></b-col>
                      <b-col class="col-2 pl-1"><b>Qte</b></b-col>
                      <b-col class="col-1 pl-2 pr-0 mr-0 ml-1"
                        ><b> PU HT </b></b-col
                      >
                      <b-col class="col-2"><b> Total HT </b></b-col>
                      <b-col class="col-1 pl-4"><b> TVA%</b></b-col>
                      <b-col class="col-2 pl-4"><b> Total TTC </b></b-col>
                    </b-row>
                    <b-row>
                      <template v-if="localProducts !== null">
                        <b-form-group
                          class="input-modal-champ col-2 p-0 mt-0 mr-0 mb-0"
                          v-if="type2 === 'avoir'"
                        >
                          <b-form-input
                            class="pl-1 pr-0"
                            id="nom1"
                            required
                            autocomplete="off"
                            :value="localProducts.products[index].nom"
                            :disabled="type2 === 'avoir'"
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group
                          class="input-modal-champ col-1 p-0 "
                          v-if="type2 === 'avoir'"
                        >
                          <b-form-input
                            class="pl-1 pr-0"
                            id="reference"
                            required
                            step="any"
                            autocomplete="off"
                            :value="localProducts.products[index].reference"
                            :disabled="type2 === 'avoir'"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          class="input-modal-champ col-2 p-0 "
                          v-if="type2 === 'avoir'"
                        >
                          <b-form-input
                            class="pl-1 pr-0"
                            id="qte1"
                            required
                            type="number"
                            step="any"
                            autocomplete="off"
                            :value="localProducts.products[index].qte"
                            :disabled="type2 === 'avoir'"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          class="input-modal-champ col-1 p-0 "
                          v-if="type2 === 'avoir'"
                        >
                          <b-form-input
                            class="pl-1 pr-0"
                            id="pu_ht2"
                            required
                            step="any"
                            autocomplete="off"
                            type="number"
                            :value="localProducts.products[index].pu_ht"
                            :disabled="type2 === 'avoir'"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          class="input-modal-champ col-2 p-0 "
                          v-if="type2 === 'avoir'"
                        >
                          <b-form-input
                            class="pl-1 pr-0"
                            id="total_ht"
                            required
                            autocomplete="off"
                            type="number"
                            step="any"
                            :value="localProducts.products[index].total_ht"
                            disabled
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          class="input-modal-champ col-1 p-0 "
                          v-if="type2 === 'avoir'"
                        >
                          <b-form-input
                            class="pl-1 pr-0"
                            id="tva"
                            required
                            autocomplete="off"
                            type="number"
                            max="100"
                            step="any"
                            :value="localProducts.products[index].tva"
                            :disabled="type2 === 'avoir'"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          class="input-modal-champ col-2 p-0"
                          v-if="type2 === 'avoir'"
                        >
                          <b-form-input
                            class="pl-1 pr-0"
                            id="ttc"
                            size="10"
                            required
                            autocomplete="off"
                            type="number"
                            step="any"
                            :value="localProducts.products[index].total_ttc"
                            disabled
                          ></b-form-input>
                        </b-form-group>
                      </template>
                      <template>
                        <b-form-group
                          class="input-modal-champ col-2 p-0 mt-0 mr-0 mb-0"
                        >
                          <b-form-input
                            class="pl-1 pr-0"
                            id="nom"
                            required
                            autocomplete="off"
                            v-model="product.nom"
                            :disabled="type2 === 'avoir'"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group class="input-modal-champ col-1 p-0 ">
                          <b-form-input
                            class="pl-1 pr-0"
                            id="reference"
                            required
                            step="any"
                            autocomplete="off"
                            v-model="product.reference"
                            :disabled="type2 === 'avoir'"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group class="input-modal-champ col-2 p-0 ">
                          <b-form-input
                            class="pl-1 pr-0"
                            id="qte"
                            required
                            type="number"
                            step="any"
                            autocomplete="off"
                            v-model="product.qte"
                            :disabled="product.reste_avoir <= 0"
                            @input="onChangeQtePuHt(product)"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group class="input-modal-champ col-1 p-0 ">
                          <b-form-input
                            class="pl-1 pr-0"
                            id="pu_ht"
                            required
                            step="any"
                            autocomplete="off"
                            type="number"
                            :disabled="product.reste_avoir <= 0"
                            v-model="product.pu_ht"
                            @input="onChangeQtePuHt(product)"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group class="input-modal-champ col-2 p-0 ">
                          <b-form-input
                            class="ht-disabled pl-1 pr-0"
                            id="total_ht"
                            required
                            autocomplete="off"
                            type="number"
                            step="any"
                            v-model="product.total_ht"
                            :max="
                              product.reste_avoir <= 0 ? 0 : product.reste_avoir
                            "
                          ></b-form-input>
                          <p class="rest-avoir-style" v-if="type2 === 'avoir'">
                            Rest avoir
                            <template v-if="product.reste_avoir <= 0"
                              >0</template
                            >
                            <template v-else>{{
                              product.reste_avoir
                            }}</template>
                          </p>
                        </b-form-group>
                        <b-form-group class="input-modal-champ col-1 p-0 ">
                          <b-form-input
                            class="pl-1 pr-0"
                            id="tva"
                            required
                            autocomplete="off"
                            type="number"
                            @input="onChangeQtePuHt(product)"
                            max="100"
                            step="any"
                            v-model="product.tva"
                            :disabled="type2 === 'avoir'"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group class="input-modal-champ col-2 p-0">
                          <b-form-input
                            class="pl-1 pr-0"
                            id="ttc"
                            size="10"
                            required
                            autocomplete="off"
                            type="number"
                            step="any"
                            v-model="product.total_ttc"
                            disabled
                          ></b-form-input>
                        </b-form-group>
                        <div v-if="type2 === 'avoir'">
                          <b-form-checkbox
                            :id="'checkbox-1' + index"
                            name="checkbox-1"
                            v-model="product.checked"
                            switch
                            :disabled="product.reste_avoir <= 0"
                            @change="initQTE(product, index)"
                          >
                          </b-form-checkbox>
                        </div>
                      </template>
                      <b-form-group
                        class="input-modal-champ col-12 p-0 mt-1 ml-1"
                        v-if="type2 === 'non-avoir'"
                      >
                        <b-form-textarea
                          class="pl-1 pr-0"
                          id="description"
                          required
                          autocomplete="off"
                          type="number"
                          v-model="product.description"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-row>
                    <hr class="separate-line col-11 m-0" />
                  </b-row>
                </b-col>
              </b-row>
              <div
                v-if="loadingUpload == true"
                class="loading-custom-template-block"
              >
                <Half-circle-spinner
                  :animation-duration="1000"
                  :size="50"
                  :color="'#4d4bac'"
                  class="loeder"
                />
              </div>
            </b-tab>
            <b-tab title="Template" @click="getHtmlTemplate">
              <b-row class="template-style">
                <b-col class="col-4">
                  <div class="form-actions mt-3 mb-5 text-aligne-center">
                    <b-button
                      @click="handleUpload"
                      class="button-succes-style ml-2"
                      size="sm"
                      variant="success"
                      :disabled="computedDisabledValidateButton.length === 0"
                    >
                      Valider
                      <font-awesome-icon
                        icon="arrow-alt-circle-right"
                        class="ml-2"
                      />
                    </b-button>
                    <div
                      v-if="loadingUpload == true"
                      class="loading-custom-template-block"
                    >
                      <Half-circle-spinner
                        :animation-duration="1000"
                        :size="50"
                        :color="'#4d4bac'"
                        class="loeder"
                      />
                    </div>
                    <div class="message text-aligne-center">
                      <div v-if="errorUpload" class="error-message">
                        <div class="error">{{ errorUpload }}</div>
                      </div>
                    </div>
                  </div>
                </b-col>
                <div
                  class="col-8"
                  id="hmt_template_block_id"
                  v-if="getTemplateObligeeToUpload"
                  v-html="getTemplateObligeeToUpload.template"
                ></div>
              </b-row>
            </b-tab>
          </b-tabs>
        </div>
      </form>
    </b-modal>
    <!-- Modal visualisation -->
    <b-modal
      ref="vusialsationAvoir"
      id="vusialsationAvoir"
      no-close-on-backdrop
      :hide-footer="true"
      :title="'Visualisation Facture'"
      @hidden="hideModal('vusialsationAvoir')"
      size="lg"
      modal-class="modal-extaction-bdd-frais"
    >
      <div id="hmt_template_block_id" v-if="body" v-html="body"></div>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      ref="recapModal"
      id="recapModal"
      size="lg"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal('recapModal')"
      modal-class="cutsom-modal-bootstrap custom-modal-template-frais-cdg"
    >
      <div class="hader mb-2">
        <div class="titleSetting text-aligne-center col-10">
          Rapport Création Facture Avoir :
          <b-button
            size="sm"
            variant="light"
            class="button-default-style ml-2 export-facture-auto"
            title="Télecharger  cette contenue"
            @click.prevent="downloadContentAutoFacture()"
          >
            <font-awesome-icon icon="cloud-download-alt"
          /></b-button>
        </div>
        <div class="iconClose" @click.prevent="hideModal('recapModal')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <b-row v-if="rapport != null" ref="recapFactureAvoir">
        <b-col
          sm="12"
          :md="rapport && rapport.updated && rapport.updated.length ? 6 : 12"
          v-if="rapport && rapport.created.length"
        >
          <Card>
            <template v-slot:body>
              <b style="color: black"
                >Facture(s) ajoutée(s) avec succès ({{
                  rapport.created.length
                }}):
              </b>
              <b-table-simple
                class="table-fiche custom-table-style mb-0 d-flex justify-content-center"
                responsive
              >
                <b-tbody>
                  <b-tr class="title-ligne">
                    <b-th class="newcolor">Société</b-th>
                    <b-th class="newcolor">Client</b-th>
                    <b-th class="newcolor">Numero Facure</b-th>
                    <b-th class="newcolor">Montant</b-th>
                    <b-th class="newcolor">Nombre des produits</b-th>
                    <b-th class="newcolor">Coefficient</b-th>
                    <b-th class="newcolor">Objet</b-th>
                    <b-th class="newcolor">Template</b-th>
                  </b-tr>
                  <b-tr
                    v-for="(item, index) in rapport.created"
                    v-bind:key="index"
                  >
                    <b-td>{{ item.vendeur }}</b-td>
                    <b-td>{{ item.client }}</b-td>
                    <b-td>{{ item.num }}</b-td>
                    <b-td>{{ item.montant_ttc }} €</b-td>
                    <b-td>{{ item.number_of_products }} </b-td>
                    <b-td>{{ item.coef }} </b-td>
                    <b-td>{{ item.objet }} </b-td>
                    <b-td>{{ item.template }} </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </template>
          </Card>
        </b-col>
        <b-col
          sm="12"
          :md="rapport && rapport.created && rapport.created.length ? 6 : 12"
          v-if="rapport && rapport.updated.length"
        >
          <Card>
            <template v-slot:body>
              <b style="color: black"
                >Facture(s) modifiée(s) ({{ rapport.updated.length }}) :</b
              >
              <b-table-simple
                class="table-fiche custom-table-style mb-0 d-flex justify-content-center"
                responsive
              >
                <b-tbody>
                  <b-tr class="title-ligne">
                    <b-th class="newcolor">Société</b-th>
                    <b-th class="newcolor">Client</b-th>
                    <b-th class="newcolor">Numero Facure</b-th>
                    <b-th class="newcolor">Montant</b-th>
                    <b-th class="newcolor">Nombre des produits</b-th>
                    <b-th class="newcolor">Coefficient</b-th>
                    <b-th class="newcolor">Objet</b-th>
                    <b-th class="newcolor">Template</b-th>
                  </b-tr>
                  <b-tr
                    v-for="(item, index) in rapport.updated"
                    v-bind:key="index"
                  >
                    <b-td>{{ item.vendeur }}</b-td>
                    <b-td>{{ item.client }}</b-td>
                    <b-td>{{ item.num }}</b-td>
                    <b-td>{{ item.montant_ttc }} €</b-td>
                    <b-td>{{ item.number_of_products }} </b-td>
                    <b-td>{{ item.coef }} </b-td>
                    <b-td>{{ item.objet }} </b-td>
                    <b-td>{{ item.template }} </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </template>
          </Card>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          :md="
            rapport && rapport.locked_factures && rapport.locked_factures.length
              ? 6
              : 12
          "
          v-if="rapport && rapport.proforma_to_facture.length"
        >
          <Card>
            <template v-slot:body>
              <b style="color: black"
                >Proforma transfère à facture ({{
                  rapport.proforma_to_facture.length
                }}) :</b
              >
              <b-table-simple
                class="table-fiche custom-table-style mb-0 d-flex justify-content-center"
                responsive
              >
                <b-tbody>
                  <b-tr class="title-ligne">
                    <b-th class="newcolor">Societe</b-th>
                    <b-th class="newcolor">Client</b-th>
                    <b-th class="newcolor">Numero Facure</b-th>
                    <b-th class="newcolor">Montant</b-th>
                    <b-th class="newcolor">Numéro proforma</b-th>
                  </b-tr>
                  <b-tr
                    v-for="(item, index) in rapport.proforma_to_facture"
                    v-bind:key="index"
                  >
                    <b-td>{{ item.vendeur }}</b-td>
                    <b-td>{{ item.client }}</b-td>
                    <b-td>{{ item.num }}</b-td>
                    <b-td>{{ item.montant_ttc }} €</b-td>
                    <b-td>{{ item.num_proforma }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </template>
          </Card>
        </b-col>
        <b-col
          v-if="rapport && rapport.locked_factures.length"
          sm="12"
          :md="
            rapport &&
            rapport.proforma_to_facture &&
            rapport.proforma_to_facture.length
              ? 6
              : 12
          "
        >
          <Card>
            <template v-slot:body>
              <b style="color: black"
                >Facture(s) verrouille(s)({{ rapport.locked_factures.length }})
                :</b
              >
              <b-table-simple
                class="table-fiche custom-table-style mb-0 d-flex justify-content-center"
                responsive
              >
                <b-tbody>
                  <b-tr class="title-ligne">
                    <b-th class="newcolor">Numero Facure</b-th>
                    <b-th class="newcolor">Montant</b-th>
                  </b-tr>
                  <b-tr
                    v-for="(item, index) in rapport.locked_factures"
                    v-bind:key="index"
                  >
                    <b-td>{{ item.num }}</b-td>
                    <b-td>{{ item.ttc }} €</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </template>
          </Card>
        </b-col>
      </b-row>
      <b-row class="mt-4" v-if="rapport != null">
        <b-col
          sm="12"
          :md="rapport && rapport.no_seq && rapport.no_seq.length ? 6 : 12"
          v-if="rapport && rapport.no_rib.length"
        >
          <Card>
            <template v-slot:body>
              <b style="color: black"
                >Société sans RIB ({{ rapport.no_rib.length }}):</b
              >
              <ul style="list-style-type: none">
                <li
                  v-for="(item, index) in rapport.no_rib"
                  v-bind:key="index"
                  style="color: black"
                >
                  {{ item }}
                </li>
              </ul>
            </template>
          </Card>
        </b-col>
        <b-col
          sm="12"
          :md="rapport && rapport.no_rib && rapport.no_rib.length ? 6 : 12"
          v-if="rapport && rapport.no_seq.length"
        >
          <Card>
            <template v-slot:body>
              <b style="color: black"
                >Société sans séquance ({{ rapport.no_seq.length }}):</b
              >
              <ul style="list-style-type: none">
                <li
                  v-for="(item, index) in rapport.no_seq"
                  v-bind:key="index"
                  style="color: black"
                >
                  {{ item }}
                </li>
              </ul>
            </template>
          </Card>
        </b-col>
      </b-row>
      <b-row class="mt-4" v-if="rapport != null">
        <b-col sm="12" ms="12" v-if="rapport && rapport.no_changes"
          ><Card>
            <template v-slot:body
              ><b style="color: black; padding-top:5px ">{{
                rapport.no_changes
              }}</b></template
            ></Card
          ></b-col
        ></b-row
      >
    </b-modal>
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    item: { required: true },
    type: { required: true },
    type2: { required: false },
    updateFunction: { required: true },
    getTemplateFunction: { required: true },
    download: { required: true },
    showDownloadButton: { required: true },
    value: { require: true },
    can_reload: { required: true },
    refereshTemplateFunction: { required: true },
    show_generate_facture_file_code: { default: true },
    downloadFactureApi: { required: false },
    visialisation: { default: false },
    IconClass: { default: '' },
    body: {
      required: false
    },
    filter: {
      required: false
    }
  },
  data() {
    return {
      loadingUpload: false,
      errorUpload: null,
      activeFormTab: true,
      activeTemplateTab: false,
      localProducts: null,
      typeToSend: null,
      rapport: null
    };
  },
  methods: {
    ...mapActions([
      'downloadFactureObligeeItem',
      'downloadMultiAvoirPdfs',
      'downloadPaiementObligeeThFactureFiltredPdf',
      'getAllConditionsPaiement'
    ]),
    downloadContentAutoFacture() {
      html2pdf(this.$refs.recapFactureAvoir, {
        margin: 0.5,
        filename:
          'Rapport' +
          moment(Date.now()).format('YYYY-MM-DD') +
          'oblige.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: 'in', format: 'legal', orientation: 'landscape' }
      });
    },
    async handleOpenAvoirVusialisationModal() {
      this.$emit('returnHistorique', true);
      this.$refs['vusialsationAvoir'].show();
    },
    initQTE(product, index) {
      if (product.checked === false) {
        product.qte = 0;
        product.pu_ht = 0;
        product.total_ht = 0;
        product.total_ttc = 0;
        product.tva = 0;
      } else {
        product.qte = this.localProducts.products[index].qte;
        product.pu_ht = this.localProducts.products[index].pu_ht;
        product.total_ht = this.localProducts.products[index].total_ht;
        product.total_ttc = this.localProducts.products[index].total_ttc;
        product.tva = this.localProducts.products[index].tva;
      }
    },
    onChangeQtePuHt(product) {
      product.total_ht =
        Math.round(
          parseFloat((product.qte + '').replace(',', '.') || 0) *
            parseFloat((product.pu_ht + '').replace(',', '.') || 0) *
            100
        ) / 100;

      let tva =
        Math.round(
          ((parseFloat((product.total_ht + '').replace(',', '.') || 0) *
            parseFloat((product.tva + '').replace(',', '.') || 0)) /
            100) *
            100
        ) / 100;

      product.total_ttc =
        Math.round(
          (parseFloat((product.total_ht + '').replace(',', '.') || 0) + tva) *
            100
        ) / 100;
    },
    async handleRefreche() {
      if (this.value != '0.00') {
        const response = await this.refereshTemplateFunction({
          lot: this.item.name,
          type: this.type,
          id_facture: this.download
        });
        if (response) {
          if (this.getTemplateObligeeToUpload.added > 0) {
            let htmlMessage =
              "<div  style='margin: auto;width: 50%;height: 250px;overflow-x: auto;' class='ModalScrol'><div> ";
            for (
              let i = 0;
              i < this.getTemplateObligeeToUpload.added_projects.length;
              i++
            ) {
              htmlMessage =
                htmlMessage +
                '<li>' +
                this.getTemplateObligeeToUpload.added_projects[i] +
                '</li>';
            }

            htmlMessage = htmlMessage + '</div></div>';
            this.$swal.fire({
              background: 'rgb(245 245 252)',
              title:
                this.getTemplateObligeeToUpload.added +
                ' dossier(s) est ajouté à la facture',
              icon: 'warning',
              locale: 'fr',
              showLoaderOnConfirm: true,
              allowOutsideClick: false,
              showCloseButton: true,
              showConfirmButton: true,
              html: htmlMessage,
              confirmButtonText: 'Ok',
              cancelButtonClass: 'btn btn-danger',
              cancelButtonColor: '#d33',
              confirmButtonColor: '#43af60',
              customClass: {
                actions: 'my-actions',
                cancelButton: 'order-2 ',
                confirmButton: 'order-1'
              }
            });
          }
          this.localProducts = JSON.parse(
            JSON.stringify(this.getTemplateObligeeToUpload)
          );
          let now = new Date();
          let dateFormate = moment(now).format('DD-MM-YYYY');
          if (
            (this.getTemplateObligeeToUpload &&
              this.getTemplateObligeeToUpload.date == null) ||
            this.getTemplateObligeeToUpload.date == ''
          ) {
            this.getTemplateObligeeToUpload.date = dateFormate;
          }
          if (
            this.getTemplateObligeeToUpload.rib == null ||
            this.getTemplateObligeeToUpload.rib == ''
          ) {
            this.getTemplateObligeeToUpload.vendeur_ribs.map(item => {
              if (item.favori === true) {
                this.getTemplateObligeeToUpload.rib = item.rib;
              }
            });
          }
          this.getTemplateObligeeToUpload?.products?.map((item, index) => {
            if (item.reste_avoir <= 0) {
              this.getTemplateObligeeToUpload.products[index].qte = 0;
              this.getTemplateObligeeToUpload.products[index].pu_ht = 0;
              this.getTemplateObligeeToUpload.products[index].total_ht = 0;
              this.getTemplateObligeeToUpload.products[index].total_ttc = 0;
              this.getTemplateObligeeToUpload.products[index].tva = 0;
            }
          });
        }
      }
    },
    async handleEditTemplate() {
      if (this.value != '0.00') {
        this.$emit('returnHistorique', true);
        this.$refs['uploadFactureTemplateModal'].show();
        this.getAllConditionsPaiement();
        let params = {};

        if (Array.isArray(this.download)) {
          params = {
            lot: this.item.name,
            type: this.type == 'avoir' ? 'missing_prime' : this.type,
            av: this.type2 == 'avoir' ? 'oui' : 'no',
            vendeur:
              this.type == 'prime' ||
              this.type == 'avoir' ||
              this.type == 'missing_prime'
                ? this.item.id_vendeur
                : this.item.id_group,
            acheteur: this.item.id_acheteur,
            item: this.item,
            av: this.type2 == 'avoir' ? 'oui' : 'no'
          };
        } else {
          params = {
            id: this.download,
            av: this.type2 == 'avoir' ? 'oui' : 'no',
            type: this.type == 'avoir' ? 'missing_prime' : this.type
          };
        }
        const response = await this.getTemplateFunction(params);
        if (response) {
          this.localProducts = JSON.parse(
            JSON.stringify(this.getTemplateObligeeToUpload)
          );
          let now = new Date();
          let dateFormate = moment(now).format('DD-MM-YYYY');
          if (
            this.getTemplateObligeeToUpload.date == null ||
            this.getTemplateObligeeToUpload.date == ''
          ) {
            this.getTemplateObligeeToUpload.date = dateFormate;
          }
          if (
            this.getTemplateObligeeToUpload.rib == null ||
            this.getTemplateObligeeToUpload.rib == ''
          ) {
            this.getTemplateObligeeToUpload.vendeur_ribs.map(item => {
              if (item.favori === true) {
                this.getTemplateObligeeToUpload.rib = item.rib;
              }
            });
          }
          this.getTemplateObligeeToUpload?.products?.map((item, index) => {
            if (item.reste_avoir <= 0) {
              this.getTemplateObligeeToUpload.products[index].qte = 0;
              this.getTemplateObligeeToUpload.products[index].pu_ht = 0;
              this.getTemplateObligeeToUpload.products[index].total_ht = 0;
              this.getTemplateObligeeToUpload.products[index].total_ttc = 0;
              this.getTemplateObligeeToUpload.products[index].tva = 0;
            }
          });
        }
        // }
      }
    },
    async handleUpload() {
      if (
        this.getTemplateObligeeToUpload &&
        this.getTemplateObligeeToUpload.rib != null
      ) {
        this.loadingUpload = true;
        this.errorUpload = null;
        let rib_tables = this.getTemplateObligeeToUpload.vendeur_ribs.filter(
          item => item.rib === this.getTemplateObligeeToUpload.rib
        );
        let data = {
          type: this.type == 'avoir' ? 'missing_prime' : this.type,
          av: this.type2 == 'avoir' ? 'oui' : 'no',
          ht: this.computedTotalHt,
          tva: this.computedTotalTva,
          ttc: this.computedTotalTTC,
          swift: rib_tables[0]?.swift,
          iban: rib_tables[0]?.iban,
          item: this.item,
          date: this.getTemplateObligeeToUpload.date,
          products: this.getTemplateObligeeToUpload.products,
          id_rib: rib_tables[0]?.id,
          payment_condition: this.getTemplateObligeeToUpload.payment_condition
        };
        if (this.type2 == 'avoir') {
          data.date_debut = this.filter.date_debut;
          data.date_fin = this.filter.date_fin;
        }
        if (Array.isArray(this.download)) {
          data.lot = this.item.name;
          data.vendeur =
            this.type == 'prime' ||
            this.type == 'avoir' ||
            this.type == 'missing_prime'
              ? this.item.id_vendeur
              : this.item.id_group;
          data.acheteur = this.item.id_acheteur;
        } else {
          data.id = this.download;
        }
        const response = await this.updateFunction(data);

        if (response.succes) {
          this.loadingUpload = false;
          this.errorUpload = null;
          this.hideModal('uploadFactureTemplateModal');
          if (this.type2 == 'avoir' && response.rapport) {
            this.rapport = response.rapport;
            this.$emit('returnHistorique', true);
            this.$refs['recapModal'].show();
          }
        } else {
          this.errorUpload = response.error;
          this.loadingUpload = false;
        }
      } else {
        this.errorUpload =
          'Une erreur est servenue ! IBAN et SWIFT obligatoire';
      }
    },
    clickFormTabs() {
      this.activeFormTab = true;
      this.activeTemplateTab = false;
    },
    async getHtmlTemplate() {
      this.activeFormTab = false;
      this.activeTemplateTab = true;
      if (this.value != '0.00') {
        this.$refs['uploadFactureTemplateModal'].show();
        var paramsTemplate = {
          p:
            this.type2 == 'avoir'
              ? this.getTemplateObligeeToUpload.products.filter(
                  i => i.checked == true
                )
              : this.getTemplateObligeeToUpload.products,
          type: this.type == 'avoir' ? 'missing_prime' : this.type
        };
        paramsTemplate.av = this.type2 == 'avoir' ? 'oui' : 'no';
        if (Array.isArray(this.download)) {
          paramsTemplate.lot = this.item.name;
          paramsTemplate.type =
            this.type == 'avoir' ? 'missing_prime' : this.type;
          paramsTemplate.av = this.type2 == 'avoir' ? 'oui' : 'no';
          paramsTemplate.vendeur =
            this.type == 'prime' ||
            this.type == 'avoir' ||
            this.type == 'missing_prime'
              ? this.item.id_vendeur
              : this.item.id_group;
          paramsTemplate.acheteur = this.item.id_acheteur;
          paramsTemplate.item = this.item;
          paramsTemplate.payment_condition = this.getTemplateObligeeToUpload.payment_condition;
          paramsTemplate.date = this.getTemplateObligeeToUpload.date;
          paramsTemplate.updatePdf = 'yes';
        } else {
          paramsTemplate.id = this.download;
          paramsTemplate.item = this.item;
          paramsTemplate.payment_condition = this.getTemplateObligeeToUpload.payment_condition;
          paramsTemplate.date = this.getTemplateObligeeToUpload.date;
          paramsTemplate.updatePdf = 'yes';
        }
        const response = await this.getTemplateFunction(paramsTemplate);
        if (response) {
        }
      }
    },
    changeFieldsValue(item) {
      if (item.key == 'tva_id') {
        if (
          this.getTemplateObligeeToUpload.tva != null &&
          this.getTemplateObligeeToUpload.tva != ''
        ) {
          let tvaCoef = Number.parseFloat(
            (this.getTemplateObligeeToUpload.tva + '')
              .replace(' ', '')
              .replace(',', '.')
          );

          let value = Number.parseFloat(
            (document.getElementById('total_ht_id').innerText + '')
              .replace(' ', '')
              .replace(',', '.')
          );

          document.getElementById('total_tva_id').innerHTML = (
            (value / 100) *
            tvaCoef
          ).toFixed(2);
          var elms = document.querySelectorAll('[id=total_ttc_id]');
          for (var i = 0; i < elms.length; i++) {
            elms[i].innerHTML = ((value / 100) * tvaCoef + value).toFixed(2);
          }
        }
      }
      this[item.champName] = item.e;
      if (item && item.e) {
        this.getTemplateObligeeToUpload[item.champName] = item.e;
        document.getElementById(item.key).innerHTML = item.e;
      } else {
        document.getElementById(item.key).innerHTML = item.value;
      }
    },
    focusInFields(item) {
      var myElement = document.getElementById(item.key);
      var rect = myElement.getBoundingClientRect();
      document.getElementById('hmt_template_block_id').scrollTop =
        rect.top + window.pageYOffset;
    },
    resetModal(ref) {
      this.loadingUpload = false;
      this.errorUpload = null;
      if (ref == 'recapModal') {
        this.rapport = null;
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.$emit('returnHistorique', false);
      this.resetModal(ref);
    },
    downloadFactureItem() {
      let params = {};
      if (Array.isArray(this.download)) {
        params.ids = this.download;
        params.type = [this.type];
      } else {
        params.id = this.download;
      }
      this.downloadFactureApi(params);
    }
  },
  computed: {
    ...mapGetters([
      'getTemplateObligeeToUpload',
      'getChargementAppelPaiementThObligeeFacture',
      'getErrorAppeliPaiementThObligeeFacture',
      'getFraisVisionFilialeData',
      'checkPermission',
      'ConditionPaiement'
    ]),
    computedFormattedTypeObligee() {
      return function(type_obligee) {
        if (this.type === 'consolidee_energie_invest') {
          switch (type_obligee) {
            case 'commission':
              return 'Commission';
            case 'surprime':
              return 'Surprime';
            case 'commission_gestion':
              return 'Commission gestion';
            default:
              return null;
          }
        }
      };
    },
    computedIndexToSeparate() {
      let separateIndexs = [];
      separateIndexs.push(0);
      const sizeProductesArray = this.getTemplateObligeeToUpload.products
        .length;
      for (let i = 1; i < sizeProductesArray; i++) {
        if (
          this.getTemplateObligeeToUpload?.products[i]?.type_obligee !==
          this.getTemplateObligeeToUpload?.products[i - 1]?.type_obligee
        ) {
          separateIndexs.push(i);
        }
      }
      return separateIndexs;
    },
    computedDisabledValidateButton() {
      let tab = this.getTemplateObligeeToUpload.products.filter(
        item => item.reste_avoir !== 0 && item.checked === true
      );
      return tab;
    },
    computedTotalHt() {
      let sum = 0;
      this.getTemplateObligeeToUpload?.products?.map(item => {
        sum += parseFloat((item.total_ht + '').replace(',', '.') || 0);
      });
      return Math.round(sum * 100) / 100;
    },
    computedTotalTva() {
      let sum = 0;
      this.getTemplateObligeeToUpload?.products?.map(item => {
        sum +=
          (parseFloat((item.total_ht + '').replace(',', '.') || 0) *
            parseFloat((item.tva + '').replace(',', '.') || 0)) /
          100;
      });
      return Math.round(sum * 100) / 100;
    },
    computedTotalTTC() {
      let sum = 0;
      this.getTemplateObligeeToUpload?.products?.map(item => {
        sum += parseFloat((item.total_ttc + '').replace(',', '.') || 0);
      });
      return Math.round(sum * 100) / 100;
    },
    computedRibs() {
      return this.getTemplateObligeeToUpload?.ribs?.map(item => {
        return item.rib;
      });
    },
    computedConditionPaiement() {
      var table = this.ConditionPaiement.map(item => {
        return {
          name: item.name,
          id: item.id
        };
      });
      return table;
    }
  },
  components: {
    DatePicker,
    Card: () => import('@/views/component/card.vue')
  }
};
</script>

<style lang="scss" scoped>
.separate-type {
  background-color: #8d8cb7;
  padding: 2px 20px;
  border-radius: 20px;
  color: #fff;
}
.rest-avoir-style {
  color: red;
  font-size: 10px;
  margin-bottom: 0px;
}
.refrech-button-style {
  border-radius: 30px;
  background-color: #9799d6;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.delete-icon-style {
  color: red;
}
.oblige-validation-frais {
  display: flex;
}
.icon-time-position {
  float: right;
}
.oblige-template-block-style {
  .input-fields-style-for-template {
    background-color: #ecedec36;
    margin: 0% 2%;
    padding: 0% 1%;
    border-radius: 16px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 113px);
    .fields-input {
      display: inline;
      label {
        font-size: 13px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        text-align: start;
        color: #2a2a2a;
        margin-left: 0%;
        margin-top: 0px;
        margin-bottom: 5px;
      }
    }
  }
  .template-style {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 113px);
    padding-right: 6px;
  }
}
.icon-plus {
  font-size: 13px;
  color: #8d8cb7;
  &:hover {
    color: #4d4bac;
  }
}
.icon-style-color:disabled,
.icon-style-color[disabled] {
  background-color: #cccccc;
  color: #b1b1b1;
}
.is-active-button {
  background-color: #4d4bac;
  color: #fff;
}
.separate-line {
  border: 1px solid #ccc;
}
.active-tab-style {
  background-color: #7978aa !important;
  color: #fff !important;
}
.ht-disabled {
  pointer-events: none;
  background-color: #e9ecef;
}
</style>
<style lang="scss">
.oblige-validation-frais {
  .template-style::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .template-style::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #d0cddc;
    border-radius: 10px;
  }
  .template-style::-webkit-scrollbar-thumb {
    background: #dadae4;
    border-radius: 7px;
  }
  .fields-input {
    label {
      font-size: 13px;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
      text-align: start;
      color: #2a2a2a;
      margin-left: 1%;
      margin-top: 0px;
      margin-bottom: 5px;
    }
    #title-template-description {
      border-radius: 15px;
    }
  }
}
.tabs-class {
  .nav-tabs .nav-link.active {
    background-color: #7978aa !important;
    color: #fff !important;
  }
}
#template_footer {
  position: absolute !important;
  bottom: 1% !important;
  right: 35% !important;
}
#content {
  margin-bottom: 100px !important;
}
</style>
